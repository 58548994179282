<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="440"
  >
    <template v-slot:activator="{on, attrs }">
      <v-btn
        class="aBtn--redTone1 d-flex ml-auto"
        small
        icon
      >
        <v-icon
          v-bind="attrs"
          v-on="on"
          small
        >
          mdi-close
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-container>
        <v-card-title class="text-h5 text-center">
          {{msg}}
        </v-card-title>
        <v-card-actions class="d-flex flex-wrap mt-4">
          <v-btn class="aBtn--primaryInv flex-grow-1" @click="dialog=false">Ne</v-btn>
          <v-btn class="primary flex-grow-1" @click="confirmDelete">Ano</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'DeletePopup',
  props: {
    deleteFunction: {required: true, type: Function},
    msg: {required: false, type:String, default:'' }
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    confirmDelete() {
      this.deleteFunction()
      this.dialog = false
    }
  }

}

</script>