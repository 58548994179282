<template>
  <div class="goldsmithDetail__container ma-0 pa-0 mt-5">
    <BuyoutItemsList :buyout-id="buyoutId" class="mb-5" />
    <GoldsmithItemEditForm v-if="$store.state.activeGoldsmithItem" :buyout-id="buyoutId" class="mb-5" />
    <GoldsmithItemsList :buyout-id="buyoutId" class="mb-5" />
    <ExportToBossButton :buyout-id="buyoutId" v-if="$store.getters.canExportToBoss" />
  </div>
</template>

<script>
import BuyoutItemsList from "../../components/goldsmithDetail/BuyoutItemsList";
import GoldsmithItemsList from "../../components/goldsmithDetail/GoldsmithItemsList";
import GoldsmithItemEditForm from "../../components/goldsmithDetail/GoldsmithItemEditForm";
import ExportToBossButton from "../../components/goldsmithDetail/ExportToBossButton";

export default {
  name: 'goldsmithDetail',
  data() {
    return {
      buyoutId: this.$route.params.id
    }
  },
  components: {
    BuyoutItemsList,
    GoldsmithItemEditForm,
    GoldsmithItemsList,
    ExportToBossButton
  }
}

</script>