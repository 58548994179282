<template>
  <div class="finishBuyout__container">
    <ContractPreview :buyout-id="buyoutId"/>
    <v-btn
    block
    class="mt-15 primary"
    @click="handleFinishBuyout"
    >
      <span v-if="!loading">Dokončit výkup</span>
      <span v-if="loading">
        Dokončování výkupu...<v-progress-linear
          indeterminate
          color="black"
          class="mb-0"
      />
      </span>
    </v-btn>

  </div>

</template>

<script>
import ContractPreview from "./ContractPreview";
import {finishBuyout} from "./api";
import {ACTION_TYPES as AT} from "../../store/action-types";

export default {
  name: 'finish-buyout-button',
  props: {
    buyoutId: {type: String, required: true}
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    async handleFinishBuyout(){
      this.loading = true
      await finishBuyout(this.buyoutId)
      await this.$store.dispatch(AT.newBuyout)
      await this.$store.dispatch(AT.displayMessage, {errorMessage: 'Výkup dokončen', many:false, isStringMessage:true})
      await this.$router.push({name: 'Home'})
      this.$router.go()
    }
  },
  components: {
    ContractPreview
  }
}
</script>