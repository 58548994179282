<template>
  <div class="ma-0 pa-0 buyoutsList__container">
    <h1>Admin stránka</h1>
  </div>
</template>

<script>

import {BASE_API_URL} from "../../api/axios-base";
import {URLS} from "../../api/config";

export default {
  name: 'admin',
  created() {
    window.open(`${BASE_API_URL}/${URLS.adminLink()}`, '_blank')
    this.$router.push({name:'Home'})
  }
}

</script>