import store from "../store";
import {ACTION_TYPES as AT} from "../store/action-types";

export const validatePassword = (oldPassword, newPassword, confirmNewPassword) => {
    if (newPassword !== confirmNewPassword) {
        store.dispatch(AT.displayErrors, {
            errorMessage: 'Nová hesla se neshodují',
            many: false,
            isStringMessage: true
        })
        return false
    } else if (newPassword.length < 8) {
        store.dispatch(AT.displayErrors, {
            errorMessage: 'Heslo musí mít alespoň 8 znaků',
            many: false,
            isStringMessage: true
        })
        return false
    } else if (newPassword === oldPassword) {
        store.dispatch(AT.displayErrors, {
            errorMessage: 'Heslo musí mít alespoň 8 znaků',
            many: false,
            isStringMessage: true
        })
        return false
    }
    return true
}