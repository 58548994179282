<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="440"
  >
    <template v-slot:activator="{on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs"></slot>
    </template>
    <v-card>
      <v-container>
        <v-card-title class="px-2 pt-0 pb-5">
          Přesunout várky
        </v-card-title>
        <v-card-text class="px-2 pb-0">
          <v-autocomplete
            :menu-props="{ offsetY: true }"
            outlined
            v-model="selectedItem"
            :items="items"
            :loading="isLoading"
            :search-input.sync="search"
            item-text="text"
            item-value="value"
            label="Císlo várky"
          />
          <div class="vDataTable__btnsWrapper vDataTable__btnsWrapper--field my-4">
            <v-text-field
              v-model="weightSub"
              label="Váha "
              :default="0"
              step="0.01"
              suffix="g"
              outlined
              type="number"
            />
            <v-btn
              large
              class="primary"
              @click="weightSub = getSerialPortData()"
              :disabled="!isSerialPortApiAvailable()"
            >
              Zvážit
            </v-btn>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex flex-wrap mt-4">
          <v-btn class="aBtn--primaryInv flex-grow-1" @click="dialog=false">Ne</v-btn>
          <v-btn class="primary flex-grow-1" @click="confirmAction">Ano</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>

import rules from "@/utils/rules";
import {fetchBatchList, moveBatch} from "@/components/batch/api";
import {getSerialPortData, isSerialPortApiAvailable} from "@/utils/serialport";

export default {
  name: 'DialogBatchReviewMove',
  computed: {
    rules() {
      return rules
    },
    selectedIds() {
      return [this.batch.id]
    }
  },
  props: {
    batch: {
      required: false, type: Object, default: () => {
      }
    },
    msg: {required: false, type: String, default: ''}
  },
  watch: {
    search: {
      handler() {
        this.fetchData()
      },
    },
    selected: {
      handler() {
        this.fetchData()
      },
    },
  },
  data() {
    return {
      search: "",
      weightSub: this.batch.weight,
      isLoading: false,
      selectedItem: null,
      items: [],
      dialog: false,
      delayTimer: 500
    }
  },
  methods: {
    getSerialPortData,
    isSerialPortApiAvailable,
    fetchData() {
      fetchBatchList({search: this.search}).then(response => {
        this.items = response.data.results.filter(i => !this.selectedIds.includes(i.id)).map(i => {
          return {
            value: i.id,
            text: `${i.identifier} | ${i.type_batch?.description || ""} - ${i.type_commodity?.description || ""} - ${i.commodity_purity?.purity || ""}`,
          }
        })
      })
    },
    reset() {
      this.search = ""
      this.selectedItem = null
    },
    confirmAction() {
      moveBatch(this.selectedItem, [{...this.batch, weightSub: this.weightSub}]).then(_ => {
        this.dialog = false
        this.$emit("update")
      })
      this.reset()
    }
  }

}

</script>