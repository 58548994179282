<template>
  <BuyoutList goldsmith />


</template>

<script>
import BuyoutList from "../../components/buyouts/Buyout-List";

export default {
  name: 'goldsmith',
  components: {BuyoutList}

}

</script>