<template>
  <v-card>
    <v-card-title>
      <v-container class="mb-5 pb-0">
        <v-row>
          <ConfirmPopup :confirm-function="createBatch" :msg="'Opravdu si přejete vytvořit várku?'">
            <template #activator="{on}">
              <v-btn v-on="on" class="primary" min-width="250" :disabled="!selected.length">Vytvořit várku</v-btn>
            </template>
          </ConfirmPopup>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="aBtn--primaryInv ml-auto"
                  icon
                  @click="showFilters = !showFilters"
              >
                <v-icon>mdi-filter</v-icon>
              </v-btn>
            </template>
            <span>Zobrazit filtry</span>
          </v-tooltip>
        </v-row>
        <template v-if="showFilters">
          <v-row>
            <v-col cols="12">
              <v-divider/>
              <v-checkbox
                  v-model="search.without_batch"
                  class="mt-5 pt-0"
                  label="Zobrazit ke zpracování"
              />
            </v-col>
          </v-row>
          <v-row>

            <v-col cols="12" md="3">
              <v-text-field
                  outlined
                  light
                  :value="formattedDate(search.date)"
                  class="formInput--datePicker"
                  label="Datum"
                  :readonly="true"
                  validate-on-blur
              >
                <template #append>
                  <v-icon @click="search.date = '' ">mdi-minus-circle</v-icon>
                  <DatePicker v-model="search.date"/>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-select
                  label="Rok"
                  outlined
                  v-model="search.year"
                  :items="years"
              />
            </v-col>
            <v-col cols="12" md="3">

              <v-select
                  label="Měsic"
                  outlined
                  v-model="search.month"
                  :items="months"
              />
            </v-col>
            <v-col cols="12" md="6" class="d-flex ga-4">
              <v-text-field
                  prepend-inner-icon="mdi-magnify"
                  label="Vyhledat..."
                  outlined
                  v-model="search.fulltext"
                  @keydown.enter="handleSearch"
              >
                <template #append>
                  <v-tooltip top>
                    <template #activator="{on}">
                      <v-icon v-on="on">mdi-information-outline</v-icon>
                    </template>
                    <span>Pro vyhledávání stiskněte enter</span>
                  </v-tooltip>
                </template>
              </v-text-field>
              <v-btn class="primary" large @click="handleSearch()">Vyhledat</v-btn>
              <v-btn class="aBtn--primaryInv" large @click="handleResetSearch()">Výchozí</v-btn>
            </v-col>
          </v-row>
        </template>
      </v-container>
    </v-card-title>

    <v-data-table
        v-model="selected"
        :headers="headers"
        :items="buyouts"
        :loading="grid.loading"
        :options.sync="options"
        :server-items-length="dataLength"
        :items-per-page="grid.defaultPageSize"
        class="elevation-2"
        :footer-props="{
          'items-per-page-text':'Počet výkupů na stránce',
          'items-per-page-options':grid.itemsPerPageOptions,
          'rowsPerPage':grid.defaultPageSize
        }"
        :show-select="true"
    >
      <template #[`item.finished_date`]="{ item }">
        <span>{{ formattedDate(item.finished_date) }}</span>
      </template>
      <template #[`item.number`]="{ item }">
        <a @click="showDetail(item)">{{ item.number }}</a>
      </template>

      <template #[`item.batch_created`]="{ item }">
        <div
            v-if="item.batch_created"
            class="gLabel gLabel--success"
        >
          <v-icon small>$circle-check</v-icon>
          Ano
        </div>
        <div
            v-else
            class="gLabel gLabel--error"
        >
          <v-icon small>$circle-exclamation</v-icon>
          Ne
        </div>
      </template>

      <template #[`item.batch_prepared`]="{ item }">
        <div
            v-if="item.batch_prepared"
            class="gLabel gLabel--success"
        >
          <v-icon small>$circle-check</v-icon>
          Ano
        </div>
        <div
            v-else
            class="gLabel gLabel--error"
        >
          <v-icon small>$circle-exclamation</v-icon>
          Ne
        </div>
      </template>

      <template #[`item.actions`]="{ item }">
        <v-row>
          <v-col cols="6" md="3">
            <v-btn small @click="showBatch(item)" class="primary" :disabled="item.batch_created">
              Zpracovat výkup
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template #no-data>
        Podmínkám neodpovídají žádná data...
      </template>
      <template #loading>
        Načítání dat...
      </template>
    </v-data-table>
  </v-card>
</template>


<script>
import {
  fetchBuyouts,
  createBatch,
} from "./api";
import {formattedDate} from "@/utils/formatters";
import ConfirmPopup from "@/components/utils/ConfirmPopup.vue";
import DatePicker from "@/components/utils/DatePicker.vue";

export default {
  name: "BuyoutProcessList",
  props: {
    goldsmith: {type: Boolean, required: false, default: false}
  },
  watch: {
    options: {
      handler() {
        this.getData()
      },
      deep: true
    },
    search: {
      handler() {
        this.getData()
      },
      deep: true
    },
    "search.date": {
      handler(newVal, oldValue) {
        let dateObject = new Date(newVal ? newVal : oldValue);
        if (dateObject) {
          this.search.year = dateObject.getFullYear()
          this.search.month = dateObject.getMonth() + 1
        }
      },
      deep: true
    },
  },
  data() {
    return {
      selected: [],
      search: {
        fulltext: "",
        without_batch: false,
        date: "",
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1
      },
      headers: [
        {text: 'Datum výkupu', value: 'finished_date'},
        {text: 'Číslo výkupu', value: 'number'},
        {text: 'Várka', value: 'batch_created'},
        {text: "Dokončeno", value: 'batch_prepared'},
        {text: '', value: 'actions', sortable: false},
      ],
      grid: {
        loading: false,
        itemsPerPageOptions: [50, 100, 200],
        defaultPageSize: 100,
        count: 0,
        next: null,
        previous: null,
      },
      filters: false,
      buyouts: [],
      loading: true,
      options: {},
      showFilters: true
    }
  },
  async mounted() {
    await this.getData()
  },
  computed: {
    years() {
      const currentYear = new Date().getFullYear();
      const y = [];
      for (let i = -10; i <= 10; i++) {
        y.push(currentYear + i);
      }
      return y
    },
    months() {
      const m = [];
      for (let i = 1; i <= 12; i++) {
        m.push(i);
      }
      return m
    },
    dataLength() {
      return this.grid.count
    },
    params() {
      return {
        page: this.options.page,
        page_size: this.options.itemsPerPage,
        ordering: this.options.sortBy[0],
        desc: this.options.sortDesc[0],
        search: this.search.fulltext,
        without_batch: this.search.without_batch,
        year: this.search.year,
        month: this.search.month,
        date: this.search.date,
      }
    }
  },
  methods: {
    formattedDate,
    async createBatch() {
      await createBatch(this.selected).then(_ => {
        this.getData()
        this.selected = []
      })
    },
    handleResponse(response) {
      this.buyouts = response.data.results
      this.grid.count = response.data.count
      this.grid.next = response.data.next
      this.grid.previous = response.data.previous
    },
    showDetail(item) {
      this.$router.push({name: 'BuyoutBatchDetail', params: {id: item.id}})
    },
    showBatch(item) {
      const routeData = this.$router.resolve({name: 'BuyoutBatchDetail', params: {id: item.id}})
      window.open(routeData.href, '_blank');
    },
    handleSearch() {
      this.options.page = 1
      this.getData()
    },
    handleResetSearch() {
      this.search = {
        fulltext: "",
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        date: "",
      }
    },
    async getData() {
      this.grid.loading = true
      await fetchBuyouts(this.params).then(response => {
        this.handleResponse(response)
        this.grid.loading = false
      })
    },
  },
  components: {DatePicker, ConfirmPopup}
}
</script>
