<template>
  <v-card max-width="600px">
    <v-form @submit.prevent="changePassword" ref="changePasswordForm" class="pa-8">
      <v-col
          cols="12"
          md="12"
      >
        <v-text-field
            label="Staré heslo"
            outlined
            :type="hidePassword ? 'password' : 'text'"
            :append-icon="hidePassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
            @click:append="hidePassword = !hidePassword"
            v-model="oldPassword"
            :rules="[rules.required]"
            validate-on-blur

        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          md="12"
      >
        <v-text-field
            label="Nové heslo"
            outlined
            :type="hideNewPassword ? 'password' : 'text'"
            :append-icon="hideNewPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
            @click:append="hideNewPassword = !hideNewPassword"
            v-model="newPassword"
            :rules="[rules.required]"
            validate-on-blur
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          md="12"
      >
        <v-text-field
            label="Nové heslo znova"
            outlined
            :type="hideConfirmPassword ? 'password' : 'text'"
            :append-icon="hideConfirmPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
            @click:append="hideConfirmPassword = !hideConfirmPassword"
            v-model="confirmNewPassword"
            :rules="[rules.required]"
            validate-on-blur
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          md="12"
      >
        <v-btn type="submit" block class="primary">Potvrdit</v-btn>
      </v-col>
    </v-form>
  </v-card>
</template>

<script>
import {
  postPasswords
} from "./api";
import {validatePassword} from "../../utils/validatepassword";
import rules from '@/utils/rules.js'
import {ACTION_TYPES as AT} from "../../store/action-types";

export default {
  name: "passwordChange",
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      hidePassword: true,
      hideNewPassword: true,
      hideConfirmPassword: true,
      rules: {
        ...rules
      },
    }
  },
  methods: {
    changePassword: async function () {
      if (this.$refs.changePasswordForm.validate() && validatePassword(this.oldPassword, this.newPassword, this.confirmNewPassword)) {
        await postPasswords({oldPassword: this.oldPassword, newPassword: this.newPassword})
            .then(() => {
              this.$store.dispatch(AT.displayMessage, {
                errorMessage: 'Heslo bylo změněno',
                many: false,
                isStringMessage: true
              })
              this.oldPassword = this.newPassword = this.confirmNewPassword = ''
            })

      }
    }
  }
}

</script>

<style scoped>

</style>