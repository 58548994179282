export default {
    accessToken: localStorage.getItem('access_token') || null,
    refreshToken: localStorage.getItem('refresh_token') || null,
    username: localStorage.getItem('username') || '',
    userShop: localStorage.getItem('user_shop') || '',
    isAdmin: localStorage.getItem('is_admin') === 'true' || false,
    userId: parseInt(localStorage.getItem('user_id') || '0', 10) || null,
    userCashRegisterIdentifier: localStorage.getItem('userCashRegisterIdentifier') || '',
    isGoldsmith: localStorage.getItem('isGoldsmith') === 'true' || false,
    hasAllShops: localStorage.getItem('has_all_shops') === 'true' || false

}