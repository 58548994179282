import {axiosBase} from "@/api/axios-base";
import {URLS} from "@/api/config";
import {AxiosResponse} from "axios";
import {IBuyoutListResponse, IQueryParams, ITypeShop, ITypeStatus} from "./config";

export const fetchBuyouts = async (params:IQueryParams): Promise<AxiosResponse<IBuyoutListResponse>> => {
    return await axiosBase.get(URLS.buyoutList(),{params:params} )
}

export const deleteBuyout = async(buyoutId:string): Promise<null> => {
    return await axiosBase.delete(URLS.deleteBuyout(buyoutId))
}

export const fetchAnother = async(route:string): Promise<AxiosResponse<IBuyoutListResponse>> => {
    return await axiosBase.get(route)
}

export const fetchVisibleStatuses = async(): Promise<AxiosResponse<Array<ITypeStatus>>> => {
    return await axiosBase.get(URLS.visibleStatuses())
}

export const fetchVisibleShops = async(): Promise<AxiosResponse<Array<ITypeShop>>> => {
    return await axiosBase.get(URLS.shopsListAllowedOnly())
}

export const exportBuyoutToBoss = async(buyoutIds:[]): Promise<null> => {
    return await axiosBase.post(URLS.exportBuyoutsToBoss(buyoutIds))
}
