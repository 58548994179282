export const MUTATION_TYPES = {
    // user
    SHOP_SET: 'SHOP::SET',
    CASH_REGISTER_SET:'CASH_REGISTER::SET',
    TOKENS_SET: 'TOKENS::SET',
    CREDENTIALS_SET: 'CREDENTIALS::SET',
    CREDENTIALS_TOKENS_REMOVE: 'CREDENTIALS_TOKENS::REMOVE',
    // new buyout
    BUYOUT_STATUS_UPDATE:'BUYOUT_STATUS::UPDATE',
    BUYOUT_ITEMS_FORM_STATUS_UPDATE:'BUYOUT_ITEMS_FORM_STATUS::UPDATE',
    CUSTOMER_FORM_STATUS_UPDATE:'CUSTOMER_FORM_STATUS::UPDATE',
    CUSTOMER_FORM_VISIBILITY_UPDATE:'CUSTOMER_FORM_VISIBILITY::UPDATE',
    CONTRACT_PREVIEW_VISIBILITY_UPDATE: 'CONTRACT_PREVIEW_VISIBILITY::UPDATE',
    IS_EMAIL_UPDATE:'IS_EMAIL::UPDATE',
    // errors
    ERRORS_STATE_UPDATE:'ERRORS_STATE::UPDATE',
    //goldsmith
    GOLDSMITH_ACTIVE_ITEM_UPDATE:'GOLDSMITH_ITEM::UPDATE',
    GOLDSMITH_ACTIVE_ITEM_REMOVE:'GOLDSMITH_ITEM::REMOVE',
    NO_WEIGHT_LEFT_UPDATE:'NO_WEIGHT_LEFT::UPDATE',
    ALL_GOLDSMITH_ITEMS_ARE_OK_UPDATE:'ALL_GOLDSMITH_ITEMS_ARE_OK::UPDATE',
}