<template>
  <div class="exportToBoss__container">
    <v-btn
        block
        class="mt-15 primary"
        @click="handleExportToBoss"
    >
      <span v-if="!loading">Exportovat do BOSS</span>
      <span v-if="loading">
        Exportování...<v-progress-linear
          indeterminate
          color="black"
          class="mb-0"
      />
      </span>
    </v-btn>

  </div>
</template>

<script>

export default {
  name: 'ExportToBossButton',
  props: {
    buyoutId: {required: true, type: String}
  },
  data() {
    return {
      loading:false
    }
  },

  methods: {
    handleExportToBoss() {
      console.log('EXPORTING TO BOSS TODO')
    }
  }
}

</script>