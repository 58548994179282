<template>
  <div>
    <h1>Timeout...</h1>
  </div>
</template>

<script>
import {ACTION_TYPES as AT} from "@/store/action-types";


export default {
  name: 'timeOut',
  mounted() {
    const routeParam = this.$store.getters.isLoggedIn ? {loginTimeout: true} : {error: true}
    this.$store.dispatch(AT.logout)
        .then(
            this.$router.push({name: 'Login', params: {...routeParam}})
        )
        .catch(err => {
          console.log(err)
        })
  }
}
</script>