import {axiosBase} from "@/api/axios-base";
import {URLS} from "@/api/config";
import {AxiosResponse} from "axios";
import {
    ICommodityType,
    IBuyoutItemGET,
    IBuyoutItemPOST,
    IBuyoutItemPOSTResponse,
    IBuyoutItemPUT,
    IBuyoutItemPUTResponse,
    ICustomer, IGender,
    ITypeStatus, IBuyoutNote, IBuyoutTotalPrice,
    ICommodityPurity, IState, IBatchType
} from "./config";

export const fetchCommodityTypes = async (): Promise<AxiosResponse<Array<ICommodityType>>> => {
    return await axiosBase.get(URLS.commodityTypesList())

}

export const fetchBatchTypes= async (): Promise<AxiosResponse<Array<IBatchType>>> => {
    return await axiosBase.get(URLS.batchTypesList())
}


export const fetchBuyoutItems = async (buyoutId: string): Promise<AxiosResponse<Array<IBuyoutItemGET>>> => {
    return await axiosBase.get(URLS.buyoutItemsList(buyoutId))
}

export const deleteBuyoutItem = async (buyouItemtId: string): Promise<null> => {
    return await axiosBase.delete(URLS.buyoutItemDelete(buyouItemtId))
}

export const createBuyoutItem = async (data: IBuyoutItemPOST): Promise<AxiosResponse<IBuyoutItemPOSTResponse>> => {
    return await axiosBase.post(URLS.buyoutItemCreate(), data)
}

export const updateBuyoutItem = async (buyoutItemId: string, data: IBuyoutItemPUT): Promise<AxiosResponse<IBuyoutItemPUTResponse>> => {
    return await axiosBase.put(URLS.buyoutItemUpdate(buyoutItemId), data)
}

export const fetchGenders = async (): Promise<AxiosResponse<Array<IGender>>> => {
    return await axiosBase.get(URLS.genderTypesList())
}

export const fetchStates = async (): Promise<AxiosResponse<Array<IState>>> => {
    return await axiosBase.get(URLS.stateTypeList())
}

export const fetchStateCZ = async (): Promise<AxiosResponse<IState>> => {
    return await axiosBase.get(URLS.stateCZ())
}

export const fetchCustomer = async (buyoutId: string): Promise<AxiosResponse<ICustomer>> => {
    return await axiosBase.get(URLS.buyoutCustomer(buyoutId))
}

export const postCustomer = async (buyoutId: string, data: ICustomer): Promise<null> => {
    return await axiosBase.post(URLS.buyoutCustomer(buyoutId), data)
}

export const updateBuyoutStatus = async (buyoutId: string, data: ITypeStatus): Promise<null> => {
    return await axiosBase.put(URLS.buyoutStatusUpdate(buyoutId), data)
}

export const fetchExistingCustomer = (nationalIdNumber: string): Promise<AxiosResponse<ICustomer>> => {
    return axiosBase.get(URLS.existingCustomer(nationalIdNumber))
}

export const fetchBuyoutNote = (buyoutId: string): Promise<AxiosResponse<IBuyoutNote>> => {
    return axiosBase.get(URLS.buyoutNote(buyoutId))
}

export const updateBuyoutNote = (buyoutId: string, data: IBuyoutNote): Promise<null> => {
    return axiosBase.put(URLS.buyoutNote(buyoutId), data)
}

export const updateBuyoutTotalPrice = (buyoutId: string, data: IBuyoutTotalPrice): Promise<null> => {
    return axiosBase.put(URLS.buyoutTotalPrice(buyoutId), data)
}

export const fetchCommodityPurities = (): Promise<AxiosResponse<Array<ICommodityPurity>>> => {
    return axiosBase.get(URLS.commodityPurities())
}

export const finishBuyout = (buyoutId: string) : Promise<null> => {
    return axiosBase.post(URLS.finishBuyout(buyoutId))
}