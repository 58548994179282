<template>
  <v-card>
    <v-card-title>Nezpracované předměty</v-card-title>
    <v-data-table
        :headers="headers"
        :items="buyoutItems"
        :loading="loading"
        class="elevation-2"
        :hide-default-footer="true"
        disable-pagination
    >
      <template #[`item.sortButton`]="{ item }">
        <v-btn v-if="item.weight_left > 0" small @click="handleProcessItem(item)" class="primary">
          Zpracovat část
        </v-btn>
        <a v-else class="text--underline">Zpracováno</a>
      </template>

      <template #no-data>
        Podmínkám neodpovídají žádná data...
      </template>
      <template #loading>
        Načítání dat...
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import {createGoldsmithItem, fetchBuyoutItems} from "./api";
import {updateBuyoutStatus} from "../newBuyout/api";
import {statusSortingData} from "./config";
import {MUTATION_TYPES as MT} from "../../store/_mutation-types";

export default {
  name: 'buyoutItemsList',
  props: {
    buyoutId: {required: true, type: String}
  },
  data() {
    return {
      loading: false,
      headers: [
        {text: 'Typ komodity', align: 'start', value: 'type_commodity.description'},
        {text: 'Ryzost', value: 'commodity_purity.purity'},
        {text: 'Celková hmotnost (g)', value: 'weight'},
        {text: 'Zbývající hmotnost (g)', value: 'weight_left'},
        {text: '', value: 'sortButton', sortable: false}
      ],
      buyoutItems: [],
      selectedItem: null
    }
  },

  async mounted() {
    this.$store.watch((state) => {
          return state.activeGoldsmithItem
        },
        () => {
          this.getData()
        },
        {deep: true})
    this.loading = true
    await this.getData()
    this.loading = false
  },
  methods: {
    async getData() {
      await fetchBuyoutItems(this.buyoutId)
          .then(response => {
            this.buyoutItems = response.data
          })
      let total_weight_left = 0
      this.buyoutItems.forEach(value => {
        total_weight_left += value.weight_left
      })
      if (total_weight_left === 0) {
        this.$store.commit(MT.NO_WEIGHT_LEFT_UPDATE, true)
      } else {
        this.$store.commit(MT.NO_WEIGHT_LEFT_UPDATE, false)
      }
    },

    async handleProcessItem(item) {
      if (!this.$store.state.statusUpdated) {
        await updateBuyoutStatus(this.buyoutId, statusSortingData)
      }
      await createGoldsmithItem(this.buyoutId, item.id)
          .then(response => {
            this.$store.commit(MT.GOLDSMITH_ACTIVE_ITEM_UPDATE, response.data.id)
          })
    }
  }
}

</script>
