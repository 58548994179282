<template>
  <div class="homepage__container">
    <NewBuyoutButton />
  </div>
</template>

<script>
import NewBuyoutButton from '@/components/home/newBuyoutButton'

export default {
  name: 'home',
  components: {
    NewBuyoutButton
  }
}
</script>
