<template>
  <v-card>
    <buyout-item-list :buyout-id="buyoutId"></buyout-item-list>
  </v-card>
</template>
<style>
</style>
<script>


import buyoutItemList from "@/components/batch/BuyoutItemList.vue";

export default {
  name: 'BuyoutBatch',
  components: { buyoutItemList},
  data() {
    return {
      buyoutId: this.$route.params.id
    }
  },
  computed: {},
  methods: {}
}
</script>