import {axiosBase} from "@/api/axios-base";
import {URLS} from "@/api/config";
import {AxiosResponse} from "axios";
import {IBatch, IBuyoutListResponse, IQueryParams, ITypeBatch} from "./config";
import {IBuyoutItemGET} from "@/components/newBuyout/config";

export const fetchBuyoutItems = async (buyoutId: string): Promise<AxiosResponse<Array<IBuyoutItemGET>>> => {
    return await axiosBase.get(URLS.batchBuyoutItemList(buyoutId))
}

export const fetchBuyoutItem = async (buyoutItemId: string) => {
    return await axiosBase.get(URLS.batchBuyoutItem(buyoutItemId))
}

export const fetchBuyouts = async (params: IQueryParams): Promise<AxiosResponse<IBuyoutListResponse>> => {
    return await axiosBase.get(URLS.batchBuyoutList(), {params: params})
}

export const updateBuyout = async (buyoutItemId: string, data: unknown) => {
    return await axiosBase.put(URLS.updateBuyout(buyoutItemId), data)
}

export const fetchBatchBuyouts = async (batchId: string) => {
    return await axiosBase.get(URLS.fetchBatchBuyoutList(batchId))
}
export const fetchAnother = async (route: string): Promise<AxiosResponse<IBuyoutListResponse>> => {
    return await axiosBase.get(route)
}

export const fetchBatchTypeList = async (): Promise<AxiosResponse<Array<ITypeBatch>>> => {
    return await axiosBase.get(URLS.batchTypesList())
}

export const fetchJewelTypeList = async (): Promise<AxiosResponse<Array<ITypeBatch>>> => {
    return await axiosBase.get(URLS.jewelTypesList())
}

export const createBatchItem = async (itemId: string | null = null, data: IBatch): Promise<AxiosResponse<Array<ITypeBatch>>> => {
    return await axiosBase.post(URLS.batchItemCreate(itemId), data)
}

export const createBuyoutBatchItem = async (itemId: string | null = null, data: IBatch): Promise<AxiosResponse<Array<ITypeBatch>>> => {
    return await axiosBase.post(URLS.batchBuyoutBatchItemCreate(itemId), data)
}

export const deleteBatchItem = async (itemId: string) => {
    return await axiosBase.delete(URLS.batchItemDelete(itemId))
}

export const updateBatchItem = async (itemId: string, data: unknown) => {
    return await axiosBase.patch(URLS.batchItemUpdate(itemId), data)
}

export const deleteBatchBuyoutItem = async (itemId: string) => {
    return await axiosBase.delete(URLS.batchBuyoutBatchItemDelete(itemId))
}

export const fetchBatchBuyoutItemList = async (itemId: string): Promise<AxiosResponse<Array<ITypeBatch>>> => {
    return await axiosBase.get(URLS.batchBuyoutBatchItemList(itemId))
}

export const fetchBatchItemList = async (itemId: string, state: string): Promise<AxiosResponse<Array<ITypeBatch>>> => {
    return await axiosBase.get(URLS.batchItemList(itemId, state))
}


export const fetchBatchItemsAssignedList = async (batchId: string) => {
    return await axiosBase.get(URLS.batchItemAssigned(batchId))
}


export const uploadImage = (batchItemId: string, data: unknown): Promise<null> => {
    return axiosBase.post(URLS.batchUploadImage(batchItemId), data, {headers: {'Content-Type': 'multipart/form-data'}})
}

export const fetchBatchStateList = async (batchId: string) => {
    return await axiosBase.get(URLS.batchStateList(batchId))
}

export const updateBatchItemStates = async (batchItems: [], state: string) => {
    return await axiosBase.post(URLS.batchItemsStateUpdate(state), batchItems)
}

export const printLabel = async (identifier: string, weight: string, price: string = "", size: string = "", purity: string = "", stone = "", secondary_stone = "") => {
    return await axiosBase.post(URLS.batchPrintLabel(), {
        identifier: identifier,
        weight: weight,
        price: price,
        size: size,
        purity: purity,
        stone: stone,
        secondary_stone: secondary_stone,
    })
}

export const createBatch = async (items: []) => {
    return await axiosBase.post(URLS.createBatch(), items)
}

export const fetchBatchList = async (params: any) => {
    return await axiosBase.get(URLS.batchList(), {params: params})
}

export const moveBatch = async (targetId: string, items: []) => {
    return await axiosBase.post(URLS.batchMove(), {targetId: targetId, items: items})
}

export const fetchBatch = async (id: string) => {
    return await axiosBase.get(URLS.fetchBatch(id))
}


export const exportToBoss = async (batchId: string, batchItems: []) => {
    return await axiosBase.post(URLS.batchExport(batchId), batchItems)
}

export const fetchBatchItemFinishedList = async (batchId: string) => {
    return await axiosBase.get(URLS.batchItemList(batchId, "4"))
}

export const saveAttributes = async (batchItemId: string, attributes: []) => {
    return await axiosBase.post(URLS.saveBatchItemAttributes(batchItemId), attributes)
}
export const fetchAttributes = async (batchItemId: string) => {
    return await axiosBase.get(URLS.fetchBatchItemAttributes(batchItemId))
}

export const deleteBatchItemAttribute = async (attrId: string) => {
    return await axiosBase.delete(URLS.deleteBatchItemAttribute(attrId))
}

export const fetchArticleCategoryGroup = async () => {
    return await axiosBase.get(URLS.fetchArticleCategoryGroup())
}

export const saveCategories = async (batchItemId: string, categories: []) => {
    return await axiosBase.post(URLS.saveBatchItemCategories(batchItemId), categories)
}

export const deleteCategory = async (categoryId: string) => {
    return await axiosBase.delete(URLS.deleteBatchItemCategory(categoryId))
}

export const batchBuyoutBatchItemJewelCreate = async (itemId: string | null = null, data: IBatch) => {
    return await axiosBase.post(URLS.batchBuyoutBatchItemJewelCreate(itemId), data)
}
export const batchBuyoutBatchItemJewelDelete = async (itemId: string)=> {
    return await axiosBase.delete(URLS.batchBuyoutBatchItemJewelDelete(itemId))
}
export const batchBuyoutBatchItemJewelList = async (itemId: string)=> {
    return await axiosBase.get(URLS.batchBuyoutBatchItemJewelList(itemId))
}




