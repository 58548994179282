export interface IBuyoutItem {
    id: string,
    type_commodity: {
        id: string,
        description: string,
        identifier: string
    },
    weight: number,
    commodity_purity: {
        id: string,
        type_commodity: string,
        purity: string,
        price_for_gram: number,
        price_deviation_percent: 0
    },
    weight_left: number
}

export const statusSortingData = {
    status_identifier: "sorting"
}

export interface IGoldsmithItemCreateResponse {
    id: string
}

export interface ITypeBranchOfGood {
    id:string,
    description:string,
    identifier:string
}

export interface ISubtypeBranchOfGood extends ITypeBranchOfGood {
    parent: string
}

export interface IGoldsmithItem {
    id: string,
    description: string | null,
    weight: number | null,
    purity: number,
    type_commodity: string,
    type_branch_of_good: ITypeBranchOfGood | null,
    subtype_branch_of_good: ISubtypeBranchOfGood | null,
    finished: boolean,
    image: string

}

export interface IGoldsmithItemUpdateData {
    description: string,
    weight: number,
    type_branch_of_good: string,
    subtype_branch_of_good: string,
    finished: boolean
}

export interface IMaxWeight {
    max_weight: number
}