<template>
  <NewBuyout :isReadonly="true" title="Detail výkupu"/>
</template>

<script>
import NewBuyout from '@/views/newBuyout/App'

export default {
  name: 'buyoutDetail',
  methods: {},
  components: {
    NewBuyout
  }
}
</script>