import user from "./states/_user"
import newBuyout from "./states/_newBuyout"
import errors from "./states/_errors"
import goldsmith from "./states/_goldsmith"

export default {
    ...user,
    ...newBuyout,
    ...errors,
    ...goldsmith
}