<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="batchItems"
        :loading="loading"
        class="elevation-0"
        :hide-default-footer="true"
        disable-pagination
    >
      <template #[`item.action`]="{ item }">
        <v-radio-group
            v-if="!item.is_fraction"
            v-model="item.action"
            row
            class="mt-0 pt-0"
        >
          <v-radio
              value="99"
          />
          <v-radio
              value="sell"
          />
        </v-radio-group>
      </template>

      <template #[`item.image`]="{item}">
        <v-icon v-if="item.image" @click="openImage(item)"
        >
          mdi-cloud-download
        </v-icon>

        <v-icon v-else
                @click="openUploadQr(item)"
        >
          mdi-qrcode-scan
        </v-icon>
      </template>

      <template #[`item.delete`]="{ item }">
        <DeletePopup class="mx-1" :delete-function="() => handleItemDelete(item)"
                     msg="Opravdu si přejete smazat tento předmět?"/>
      </template>

      <template #no-data>
        Podmínkám neodpovídají žádná data...
      </template>
      <template #loading>
        Načítání dat...
      </template>
    </v-data-table>

    <v-dialog
        v-model="qrDialog"
        activator="parent"
        width="auto"
    >
      <v-card>
        <qrcode-vue :value="this.qrValue" size="300" level="M"/>
      </v-card>
    </v-dialog>

  </div>

</template>
<script>

import {
  deleteBatchItem
} from "@/components/batch/api";
import QrcodeVue from 'qrcode.vue'
import DeletePopup from "@/components/utils/DeletePopup.vue";
import {reverse} from "@/utils/reverse";

export default {
  name: 'BuyoutBatchItemJewelList',
  components: { DeletePopup, QrcodeVue},
  props: {
    batchItems: {required: true, type: Array},
  },
  data() {
    return {
      expanded: [],
      loading: false,
      selectedItem: null,
      qrDialog: false,
      qrValue: null,
    }
  },
  computed: {
    headers() {
      return [
          {text: 'Typ', align: 'start', value: 'type_jewel.description'},
          {text: 'Celková hmotnost (g)', value: 'weight'},
          {text: 'Popís', value: 'description'},
          {text: 'Výkup', value: 'buyout.number'},
          {text: 'Obrázek', value: 'image'},
          {text: '', value: 'delete'},
          {text: '', value: 'edit'},
          {text: '', value: 'sortButton', sortable: false}
      ]
    },
    hasNoItems() {
      return !this.batchItems.length
    },
  },
  methods: {
    async reload() {
      this.$emit('reload')
    },
    async handleItemDelete(item) {
      await deleteBatchItem(item.id).then(() => {
        this.reload()
      })
    },
    async openUploadQr(item) {
      this.qrValue = reverse('batch:batch_item_upload_image', [item.id])
      console.log(this.qrValue);
      this.qrDialog = !this.qrDialog
    },
    openImage(item) {
      window.open(item.image, '_blank')
    },
    async updateComplete() {
      this.$emit('updateComplete', this.batchItems)
    },
  }
}

</script>
