<template>
  <div>
    <buyout-batch-item-create class="mb-6" :item="batch_item" @crateItem="handleCrate"/>
    <v-data-table
        :headers="headers"
        :items="batchItems"
        :loading="loading"
        :hide-default-footer="true"
        disable-pagination
        show-expand
        :expanded.sync="expanded"
    >
      <template #[`item.finished`]="{item}">
        <v-icon v-if="item.finished">mdi-lock</v-icon>
      </template>

      <template #[`item.action`]="{ item }">
        <DeletePopup v-if="!item.finished" class="mx-1" :delete-function="() => handleItemDelete(item)"
                     msg="Opravdu si přejete smazat tento předmět?"/>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <buyout-batch-item-jewel-create :id="item.id" :buyout="buyout"
                                          @create="updateBatchBuyoutItems(item)"></buyout-batch-item-jewel-create>
          <buyout-batch-item-jewel-list :batch-items="item.batch_buyout_items"
                                        @reload="updateBatchBuyoutItems(item)"></buyout-batch-item-jewel-list>
        </td>

      </template>

      <template #no-data>
        Podmínkám neodpovídají žádná data...
      </template>
      <template #loading>
        Načítání dat...
      </template>
    </v-data-table>
  </div>

</template>
<script>

import {batchBuyoutBatchItemJewelList, deleteBatchBuyoutItem, fetchBatchBuyoutItemList} from "./api";
import DeletePopup from "@/components/utils/DeletePopup.vue";
import {EVENT_BUS_TYPE} from "@/eventBus/eventTypes";
import eventBus from "@/eventBus";
import BuyoutBatchItemCreate from "@/components/batch/BuyoutBatchItemCreate.vue";
import BuyoutBatchItemJewelCreate from "@/components/batch/BuyoutBatchItemJewelCreate.vue";
import BuyoutBatchItemJewelList from "@/components/batch/BuyoutBatchItemJewelList.vue";

export default {
  name: 'buyoutBatchItemList',
  components: {BuyoutBatchItemJewelList, BuyoutBatchItemJewelCreate, BuyoutBatchItemCreate, DeletePopup},
  props: {
    batch_item: {required: true, type: Object}
  },
  data() {
    return {
      buyout: this.batch_item.buyout,
      expanded: [],
      loading: false,
      headers: [
        {text: '', value: 'finished'},
        {text: 'Typ', align: 'start', value: 'type_batch.description'},
        {text: 'Typ komodity', align: 'start', value: 'type_commodity.description'},
        {text: 'Ryzost', align: 'start', value: 'commodity_purity.purity'},
        {text: 'Celková hmotnost (g)', value: 'weight'},
        {text: '', value: 'action', sortable: false}
      ],
      batchItems: [],
      selectedItem: null,
      qrDialog: false,
      qrValue: "",
    }
  },
  async mounted() {
    eventBus.$on(EVENT_BUS_TYPE.BATCH_PROCESS_RELOAD_SUBGRID, () => {
      this.getData()
    })
    this.loading = true
    await this.getData()
    this.loading = false
  },
  methods: {
    async updateBatchBuyoutItems(item) {
      await batchBuyoutBatchItemJewelList(item.id).then(response => {
        item.batch_buyout_items = response.data
      })
    },
    async handleCrate() {
      await this.getData()
      this.$emit("updateItem", this.batch_item)
    },
    async handleItemDelete(item) {
      await deleteBatchBuyoutItem(item.id).then(() => {
        this.getData()
        this.$emit("updateItem", this.batch_item)
      })
    },
    async getData() {
      await fetchBatchBuyoutItemList(this.batch_item.id).then(response => {
        this.batchItems = response.data
      })
    },
  }
}

</script>

<style scoped lang="scss">
:deep(.v-data-table) {
  border: 1px solid #bb9765;
  border-left: 4px solid #bb9765;
  border-radius: 0;
  margin: 0 0 0 14px;

  th,
  tr:not(:last-child, .v-data-table__expanded__row) > td:not(.v-data-table__mobile-row) {
    border-bottom: 1px solid #bb9765 !important;
  }

  .v-data-table__expanded__row {
    background: #f5f6f7;
  }

  .v-data-table {
    margin: 16px 14px 16px 14px;
  }

  .v-data-table-header {
    background: rgba(#bb9765, 0.12);
  }
}
</style>
