<template>
  <v-container fill-height fluid class="pa-0 ma-0 primary login__container gold">
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-5">
          <v-card-text>
            <v-form @submit.prevent="submitLogin" ref="loginForm">
              <v-text-field
                  prepend-icon="mdi-account"
                  name="name"
                  label="Uživatelské jméno"
                  type="text"
                  v-model="inputName"
                  :rules="[rules.required]"
              />
              <v-text-field
                  prepend-icon="mdi-lock"
                  name="password"
                  label="Heslo"
                  :type="hidePassword ? 'password' : 'text'"
                  :append-icon="hidePassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                  @click:append="hidePassword = !hidePassword"
                  v-model="inputPassword"
                  :rules="[rules.required]"
              />
              <v-btn style="margin-top:10px" block color="primary" type="submit">Přihlásit se</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>


<script>
import rules from '@/utils/rules.js'
import {ACTION_TYPES as AT} from "@/store/action-types";

export default {
  name: 'login',

  props: {
    loginTimeout: {type: Boolean, required: false, default: false},
    error: {type: Boolean, required: false, default: false}
  },
  mounted() {
    if (this.loginTimeout) {
      this.$store.dispatch(AT.displayErrors, {errorMessage: 'Přihlášení vypršelo', many: false, isStringMessage: true})
    }
    if (this.error) {
      this.$store.dispatch(AT.displayErrors, {
        errorMessage: 'Neplatné přihlašovací údaje',
        many: false,
        isStringMessage: true
      })
    }
  },

  data() {
    return {
      inputName: '',
      inputPassword: '',
      hidePassword: true,
      rules: {
        ...rules
      },
      isLoginTimeout: this.loginTimeout
    }
  },
  methods: {
    async submitLogin() {
      if (this.$refs.loginForm.validate()) {
        this.$store.dispatch(AT.login, {username: this.inputName, password: this.inputPassword})
            .then(() => {
              this.$router.go()
              this.$router.push({name: 'Home'})
            })
      }
    },
  }
}
</script>

