<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="700"
  >
    <template v-slot:activator="{on, attrs }">
      <v-btn icon small class="aBtn--primaryInv">
        <v-icon
            v-bind="attrs"
            v-on="on"
        >
          mdi-plus
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-container v-if="loading">
        <v-card-title class="text-h5 text-center pa-2 mb-4">
          Obory
        </v-card-title>

        <v-skeleton-loader type="list-item@5"></v-skeleton-loader>
      </v-container>

      <v-container v-else>
        <v-card-title class="text-h5 text-center pa-2 mb-4">
          Obory
        </v-card-title>

        <v-card-text class="pa-2">
          <v-form @submit.prevent="confirmSave" ref="categoriesForm">
            <v-container
                v-for="(group, i) in categoryGroups" :key="i"
                class="mb-2"
                style="padding: 0 2px;"
            >
              <v-row>
                <v-col cols="12" md="6">
                  {{ group.required ? group.name + "*" : group.name }}
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                      label="Typ"
                      outlined
                      v-model="group.value"
                      :items="group.article_categories"
                      :item-text="(value) => value.name"
                      :item-value="(value) => value"
                      :rules="group.required ?[rules.required]: []"
                      validate-on-blur
                  />
                </v-col>
              </v-row>
            </v-container>

            <v-divider class="mt-8"/>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex flex-wrap mt-4">
          <v-btn class="aBtn--primaryInv flex-grow-1" @click="dialog=false">Zpět</v-btn>
          <v-btn class="primary flex-grow-1" @click="confirmSave">Uložit</v-btn>
        </v-card-actions>
      </v-container>

    </v-card>
  </v-dialog>
</template>

<script>

import {fetchArticleCategoryGroup, saveCategories} from "@/components/batch/api";
import rules from '@/utils/rules.js'
import {ACTION_TYPES as AT} from "@/store/action-types";

export default {
  props: {
    id: {required: true, type: String},
    msg: {required: false, type: String, default: ''}
  },
  data() {
    return {
      loading: false,
      categoryGroups: [],
      dialog: false,
      rules: {
        ...rules
      },
    }
  },
  watch: {
    dialog: {
      handler() {
        this.getData()
      }
    }
  },
  methods: {
    confirmSave() {
      if (this.$refs.categoriesForm.validate()) {
        saveCategories(this.id, this.categoryGroups).then(() => {
          this.dialog = false
          this.$emit('save')
          this.$store.dispatch(AT.displayMessage, {
            errorMessage: 'Kategorie byly úspěšně uloženy',
            many: false,
            isStringMessage: true
          })
        })
      }
    },
    getData() {
      this.loading = true
      this.getCategoryGroups().then(() => this.loading = false)
    },
    async getCategoryGroups() {
      fetchArticleCategoryGroup().then(response => {
        this.categoryGroups = response.data.map((i) => ({value: "", ...i}))
      })
    },
  }
}

</script>