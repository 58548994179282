<template>
  <v-form @submit.prevent="saveCustomer" ref="customerForm">
    <v-container>
      <v-row>
        <v-col
            cols="12"
            md="6"
        >
          <v-checkbox
              v-model="formData.foreigner"
              label="Cizinec"
              @change="change"
              :disabled="isReadonly"
          />
        </v-col>
      </v-row>
      <v-divider class="customerForm__divider"/>
      <v-row>
        <v-col
            cols="12"
            md="6"
        >
          <v-text-field
              outlined
              v-model="formData.national_id_number"
              :label="formData.foreigner === true ? 'Datum narození (DD.MM.YYYY)*': 'Rodné číslo*'"
              @change="nationalIdChange"
              :rules="formData.foreigner === true ? [inputRules.required, inputRules.isValidDate] : [inputRules.required, inputRules.isValidInteger]"
              :disabled="isReadonly"
              validate-on-blur
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
            cols="12"
            md="6"
        >
          <v-text-field
              outlined
              v-model="formData.id_card_number"
              label="Číslo OP / pasu*"
              @change="change"
              :rules="[inputRules.required]"
              :disabled="isReadonly"
              validate-on-blur
          />
        </v-col>
        <v-col
            cols="12"
            md="6"
        >

          <v-text-field
              outlined
              light
              :value="formatDate(formData.id_card_validity_date)"
              class="formInput--datePicker"
              label="Platnost dokladu*"
              @change="change"
              :rules="[inputRules.required]"
              :readonly="true"
              :disabled="isReadonly"
              validate-on-blur
          >
            <template v-slot:append v-if="!isReadonly">
              <DatePicker v-model="formData.id_card_validity_date"/>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            cols="12"
            md="6"
        >
          <v-text-field
              outlined
              v-model="formData.id_given_by"
              label="Doklad vydal*"
              @change="change"
              :rules="[inputRules.required]"
              :disabled="isReadonly"
              validate-on-blur
          />
        </v-col>
      </v-row>
      <v-divider class="customerForm__divider"/>
      <v-row>
        <v-col
            cols="12"
            md="6"
        >
          <v-text-field
              outlined
              v-model="formData.first_name"
              label="Křestní jméno*"
              @change="change"
              :rules="[inputRules.required]"
              :disabled="isReadonly"
              validate-on-blur
          />
        </v-col>
        <v-col
            cols="12"
            md="6"
        >
          <v-text-field
              outlined
              v-model="formData.title_before_name"
              label="Titul před jménem"
              @change="change"
              :disabled="isReadonly"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
            cols="12"
            md="6"
        >
          <v-text-field
              outlined
              v-model="formData.last_name"
              label="Příjmení*"
              @change="change"
              :rules="[inputRules.required]"
              :disabled="isReadonly"
              validate-on-blur
          />
        </v-col>
        <v-col
            cols="12"
            md="6"
        >
          <v-text-field
              outlined
              v-model="formData.title_after_name"
              label="Titul za jménem"
              @change="change"
              :disabled="isReadonly"
          />
        </v-col>

      </v-row>
      <v-row>
        <v-col
            cols="12"
            md="6"
        >
          <v-text-field
              outlined
              v-model="formData.email"
              label="Email*"
              @change="change"
              :rules="formData.no_email ? [] : [inputRules.required, inputRules.isValidEmail]"
              :disabled="isReadonly"
              validate-on-blur
          />
        </v-col>
        <v-col
            cols="12"
            md="3"
        >
          <v-checkbox
              v-model="formData.no_email"
              label="Zákazník nemá email"
              @change="change"
              @click="changeEmail"
              :disabled="isReadonly"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
            cols="12"
            md="6"
        >
          <v-select
              :menu-props="{ offsetY: true }"
              outlined
              attach
              v-model="formData.gender"
              :items="genderSelectList"
              label="Pohlaví*"
              @change="change"
              :rules="[inputRules.required]"
              :disabled="isReadonly"
              validate-on-blur
          />
        </v-col>
      </v-row>
      <v-divider class="customerForm__divider"/>
      <v-row>
        <v-col
            cols="12"
            md="6"
        >
          <v-text-field
              outlined
              v-model="formData.address"
              label="Adresa*"
              @change="change"
              :rules="[inputRules.required]"
              :disabled="isReadonly"
              validate-on-blur
          />
        </v-col>
        <v-col
            cols="12"
            md="6"
        >
          <v-text-field
              outlined
              v-model="formData.city"
              label="Město*"
              @change="change"
              :rules="[inputRules.required]"
              :disabled="isReadonly"
              validate-on-blur
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
            cols="12"
            md="6"
        >
          <v-text-field
              outlined
              v-model="formData.city_id"
              label="PSČ*"
              @change="change"
              :rules="[inputRules.required, inputRules.isValidInteger]"
              :disabled="isReadonly"
              validate-on-blur
          />
        </v-col>
        <v-col
            cols="12"
            md="6"
        >
          <v-autocomplete
              :menu-props="{ offsetY: true }"
              outlined
              attach
              v-model="formData.state"
              :items="states"
              label="Stát*"
              @change="change"
              :rules="[inputRules.required]"
              :disabled="isReadonly"
              validate-on-blur
              :item-text="(value) => value.description"
              :item-value="(value) => value"
          />
        </v-col>
      </v-row>
      <v-divider class="customerForm__divider"/>
      <v-row>
        <v-col
            cols="12"
            md="6"
        >
          <v-checkbox
              v-model="formData.politically_exposed_person"
              label="Prodávající je politicky exponovaná osoba"
              @change="change"
              :disabled="isReadonly"
          />
        </v-col>
      </v-row>
      <v-divider class="customerForm__divider"/>
      <v-row>
        <v-col
            cols="12"
            md="3"
        >
          <v-text-field
              outlined
              v-model="formData.dialling_code"
              label="Telefonní předvolba*"
              @change="change"
              :rules="[inputRules.required, inputRules.isValidDialingCode]"
              :disabled="isReadonly"
              validate-on-blur
          />
        </v-col>
        <v-col
            cols="12"
            md="5"
        >
          <v-text-field
              outlined
              v-model="formData.phone_number"
              label="Telefonní číslo*"
              @change="change"
              :rules="formData.no_phone_number ?  []:[inputRules.required,inputRules.isValidInteger]"
              :disabled="isReadonly"
              validate-on-blur
          />
        </v-col>
        <v-col
            cols="12"
            md="4"
        >
          <v-checkbox
              v-model="formData.no_phone_number"
              label="Zákazník neudává telefonní číslo"
              @change="change"
              :disabled="isReadonly"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
            cols="12"
            md="6"
        >
          <v-text-field
              outlined
              v-model="formData.citizenship"
              label="Státní občanství*"
              @change="change"
              :rules="[inputRules.required]"
              :disabled="isReadonly"
              validate-on-blur
          />
        </v-col>
        <v-col
            cols="12"
            md="6"
        >
          <v-text-field
              outlined
              v-model="formData.birthplace"
              label="Místo narození*"
              @change="change"
              :rules="[inputRules.required]"
              :disabled="isReadonly"
              validate-on-blur
          />
        </v-col>
      </v-row>
      <v-divider class="customerForm__divider"/>
      <v-row>
        <v-col
            cols="12"
            md="12"
        >
          <v-textarea
              outlined
              v-model="note"
              label="Poznámka"
              @change="change"
              :disabled="isReadonly"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
            cols="12"
            md="6"
        />
        <v-col
            cols="12"
            md="6"
        >
          <v-btn type="submit" class="primary" block :disabled="isButtonDisabled" v-if="!isReadonly">Uložit</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import rules from '@/utils/rules.js'
import {ACTION_TYPES as AT} from "../../store/action-types";
import {
  fetchGenders,
  fetchCustomer,
  postCustomer,
  fetchExistingCustomer,
  fetchBuyoutNote,
  updateBuyoutNote,
  fetchStates,
  fetchStateCZ
} from "./api";
import {buyoutNoteData} from "./config";
import {formattedDate} from "@/utils/formatters";
import DatePicker from "../utils/DatePicker";

export default {
  name: 'customerForm',
  props: {
    buyoutId: {type: String, required: true},
    isReadonly: {type: Boolean, required: false, default: false}
  },
  data() {
    return {
      inputRules: {
        ...rules
      },
      genders: [],
      states: [],
      formData: {
        foreigner: false,
        national_id_number: '',
        id_card_number: '',
        id_card_validity_date: '',
        id_given_by: '',

        first_name: '',
        last_name: '',
        gender: '',
        title_before_name: '',
        title_after_name: '',
        email: '',
        no_email: false,

        address: '',
        state: '',
        city: '',
        city_id: '',

        politically_exposed_person: false,
        dialling_code: '',
        phone_number: '',
        no_phone_number: false,

        birthplace: '',
        citizenship: '',
      },
      note: '',
      error: false,
    }
  },
  async mounted() {
    await fetchGenders()
        .then(response => {
          this.genders = response.data
        })
    await fetchStates()
        .then(response => {
          this.states = response.data
        })

    await fetchCustomer(this.buyoutId)
        .then(response => {
          this.formData = response.data
          this.changeEmail()
        })
    await fetchBuyoutNote(this.buyoutId)
        .then(response => {
          this.note = response.data.note
        })
    if (this.formData.dialling_code === '') {
      this.formData.dialling_code = '+420'
    }
    if (this.formData.state.description === ''){
      await fetchStateCZ()
        .then(response => {
          this.formData.state = response.data
        })
    }

  },
  computed: {
    isButtonDisabled() {
      return this.$store.state.customerFormIsSubmitted
    },
    genderSelectList() {
      return this.genders.map(gender => {
        return {
          value: gender.id,
          text: gender.description
        }
      })
    }
  },
  methods: {
    change() {
      this.$store.dispatch(AT.customerUnsavedChange)
    },
    changeEmail() {
      this.$store.dispatch(AT.isEmailState, !this.formData.no_email)
    },
    async nationalIdChange() {
      await this.change()
      if (this.formData.national_id_number !== '') {
        await fetchExistingCustomer(this.formData.national_id_number)
            .then(resp => this.formData = resp.data)
            .catch(() => {
            })
      }
    },
    async saveCustomer() {
      if (this.$refs.customerForm.validate()) {
        this.changeEmail()
        if (this.formData.no_phone_number) {
          this.formData.phone_number = null
        }
        let data = {...this.formData}
        data.state = this.formData.state.id
        await postCustomer(this.buyoutId, data)
            .catch(() => {
              this.error = true
              this.$store.dispatch(AT.customerUnsavedChange)
            })
        if (!this.error) {
          await this.$store.dispatch(AT.saveCustomer)
        }
        if (this.note !== '' && this.note !== undefined) {
          await updateBuyoutNote(this.buyoutId, buyoutNoteData(this.note))
        }
        this.error = false
        window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
      }
    },

    formatDate(value) {
      return formattedDate(value)
    }
  },
  components: {
    DatePicker
  }

}

</script>