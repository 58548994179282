<template>
  <div class="homepage__buyoutButton">
    <v-btn
      x-large
      block
      :ripple="false"
      class="primary text-h5"
      @click.prevent="startNewBuyout"
    >
      <span v-if="!loading">Nový výkup</span>
      <span v-else>Načítání...<v-progress-linear
          indeterminate
          color="black"
          class="mb-0"
      /></span>
    </v-btn>
  </div>
</template>

<script>

import {ACTION_TYPES as AT} from "../../store/action-types";
import {createNewBuyout} from "./api";

export default {
  name: 'newBuyoutButton',
  data() {
    return {
      loading: false
    }
  },
  methods: {
    async startNewBuyout() {
      if (this.$store.state.userCashRegisterIdentifier !== '') {
        this.loading = true
        await this.$store.dispatch(AT.newBuyout)
        await createNewBuyout()
            .then((response) => {
              this.$router.push({name: 'NewBuyout', params: {id: response.data.id}})
            })
      } else {
        this.$store.dispatch(AT.displayErrors, {
          errorMessage: 'Není zvolena pokladna!',
          many: false,
          isStringMessage: true
        })
      }

    }
  }
}
</script>