<template>
  <div class="ma-0 pa-0 buyoutsList__container">
<!--    <h1>Seznam výkupů</h1>-->
  <buyout-list></buyout-list>

  </div>
</template>

<script>


import BuyoutList from "../../components/buyouts/Buyout-List";
export default {
  name: 'buyoutsList',
  components: {BuyoutList}
}

</script>