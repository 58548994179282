<template>
  <QrcodeVue :value="qrValue()" size="300" level="H" v-if="$store.getters.canFinishBuyout"/>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import {WEBSOCKETS} from "@/api/config";
// import {statusRedeemedData} from "./config";
// import {updateBuyoutStatus} from "./api";
import {URLS} from "@/api/config";
import {ACTION_TYPES as AT} from "../../store/action-types";
import {BASE_API_URL} from "../../api/axios-base";

export default {
  name: 'qrComponent',
  props: {
    buyoutId: {type: String, required: true}
  },
  data() {
    return {
      socket: undefined,
      socketMessageData: {signed: undefined, buyout_id: undefined},
    }
  },

  mounted() {
    this.startWatch()
    this.initConnection()
  },
  beforeDestroy(){
    this.socket.close()
  },
  methods: {

    qrValue() {
      return [this.finishBuyoutURL(), this.buyoutId, 'buyout-gold-signature'].join(';')
    },

    finishBuyoutURL() {
      return `${BASE_API_URL}/${URLS.finishBuyout(this.buyoutId)}`
    },

    startWatch() {
      this.$store.watch((state) => {
            return state.customerFormIsSubmitted
          },
          (newVal) => {
            if (!newVal) {
              this.socket.close()
            }
          },
          {
            deep: true
          })
    },

    initConnection() {
      this.socket = new WebSocket(WEBSOCKETS.finishBuyout())
      this.socket.onopen = () => {
        this.socket.onmessage = ({data}) => {
          this.socketMessageData = JSON.parse(data)
          this.handleSocketMessage()
        }
      }
    },
    handleSocketMessage() {
      if (this.socketMessageData.buyout_id === this.buyoutId && this.socketMessageData.signed) {
        this.finishBuyout()
        this.socket.close()
      }
    },

    async finishBuyout() {
      // await updateBuyoutStatus(this.buyoutId, statusRedeemedData)
      await this.$store.dispatch(AT.newBuyout)
      await this.$store.dispatch(AT.displayMessage, {errorMessage: 'Výkup dokončen', many:false, isStringMessage:true})
      await this.$router.push({name: 'Home'})
      this.$router.go()
    }
  },

  components: {
    QrcodeVue
  },
}
</script>