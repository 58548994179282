<template>
  <v-app v-if="!$route.meta.allowAnonymous" class="gold">
    <!-- ERROR POPUP   -->
    <v-snackbar
        v-model="error"
        color="red"
        height="auto"
        width="400px"
    >
      <div v-for="value in errorMessage" :key="value" class="text-body-1 text-center">{{ value }}</div>
      <template v-slot:action="{ attrs }">
        <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="closeError"
            right

        >
          Zavřít
        </v-btn>
      </template>
    </v-snackbar>
    <!-- END ERROR POPUP   -->
    <NavBar/>
    <v-main fill-height>
      <v-container>
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
  <v-app v-else>
    <!-- ERROR POPUP ON ANONYMOUS VIEWS   -->
    <v-snackbar
        v-model="error"
        color="red"
        height="auto"
        width="400px"
    >
      <div v-for="value in errorMessage" :key="value" class="text-body-1 text-center">{{ value }}</div>
      <template v-slot:action="{ attrs }">
        <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="closeError"
            right

        >
          Zavřít
        </v-btn>
      </template>
    </v-snackbar>
    <!-- END ERROR POPUP   -->
    <v-main fill-height>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from "./components/navbar/NavBar";
import {ACTION_TYPES as AT} from "@/store/action-types";

export default {
  name: 'App',

  data: () => ({
    errorMessage: [],
    error: false
  }),
  created() {
    // watch if error occurred on axios call
    this.$store.watch((state) => {
          return state.isActive
        },
        (newVal, oldVal) => {
          if (newVal && !oldVal) {
            this.showError(this.errorMsg, this.errorMany, this.errorIsString)
          }
        },
        {
          deep: true
        }
    )

  },
  computed: {
    errorMsg() {
      return this.$store.state.errorMessage || ''
    },
    errorMany() {
      return this.$store.state.many || false
    },
    errorIsString() {
      return this.$store.state.isStringMessage || false
    },
    messagePrefix() {
      return this.$store.state.isNormalMessage ? '' : 'Chyba:'
    }
  },

  methods: {
    // handle error
    showError(errorData, many = false, isSingleMessage = false) {
      this.errorMessage = []
      this.error = true
      if (!isSingleMessage) {
        if (many) {
          for (let [key, value] of Object.entries(errorData)) {
            if (key !== 'detail') {
              this.errorMessage.push(`${this.messagePrefix} ${key} - ${value}`)
            } else {
              this.errorMessage.push(`${this.messagePrefix} ${value}`)
            }
          }
        } else {
          this.errorMessage.push(`${this.messagePrefix} ${errorData.key} - ${errorData.value}`)
        }

      } else {
        this.errorMessage.push(`${this.messagePrefix} ${errorData}`)
      }
    },
    // close error
    closeError() {
      this.error = false
    },
    clearErrors() {
      this.errorMessage = []
      this.$store.dispatch(AT.hideErrors)

    }
  },
  watch: {
    // clear error data
    error(newVal, oldVal) {
      if (!newVal && oldVal) {
        this.clearErrors()
      }
    }
  },
  components: {
    NavBar
  }
};
</script>
