const decimal = /^[0-9]+\.[0-9]+$/
const integer = /^[0-9]+$/
const email = /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/
const date = /^(0?[1-9]|[12][0-9]|3[01])[. ]{1,2}(0?[1-9]|1[012])[. ]{1,2}(19|20)\d\d$/
const diallingCode = /^[+][0-9]{1,6}$/

export default {
 decimal,
 integer,
 email,
 date,
 diallingCode
}