export interface ICommodityType {
    id: string,
    description: string,
    price_for_gram: number
}

export interface IBatchType {
    id: string,
    description: string,
}


export interface ICommodityPurity {
    id: string,
    type_commodity:string,
    purity: number,
    price_for_gram: number,
    price_deviation_percent: number
}

export interface IBuyoutItemGET {
    id: string,
    type_commodity: {
        id:string,
        description:string,
        identifier: string
    },
    weight: number,
    commodity_purity: ICommodityPurity,
    surcharge: number,
    surcharge_note: string
}

export interface IBuyoutItemPUT {
    type_commodity: string,
    weight: number,
    commodity_purity: string,
    surcharge: number,
    surcharge_note: string
}

export interface IBuyoutItemPUTResponse extends IBuyoutItemPUT {
    id: string
}

export interface IBuyoutItemPOST {
    type_commodity: string,
    weight: number,
    buyout: string,
    commodity_purity: string,
    surcharge: number,
    surcharge_note: string
}

export interface IBuyoutItemPOSTResponse {
    id: string
}

export interface IGender {
    id: string,
    description: string
}

export interface IState {
    id: string,
    description: string
}

export interface ICustomer {
    foreigner: boolean,
    national_id_number: string,
    id_card_number: number,
    id_card_validity_date: string,
    id_given_by: string,
    first_name: string,
    last_name: string,
    gender: string,
    title_before_name: string | null,
    title_after_name: string | null,
    email: string | null,
    no_email:boolean
    address: string,
    state: string,
    city: string,
    city_id: number,
    politically_exposed_person: boolean,
    dialling_code:string,
    phone_number: number | null,
    no_phone_number:boolean,
    birthplace: string,
    citizenship: string
}

export interface ITypeStatus {
    status_identifier: string
}

export const statusNotRedeemedData = {
    status_identifier: "not_redeemed"
}

export const statusRedeemedData = {
    status_identifier: "redeemed"
}

export interface IBuyoutNote {
    note: string
}

export const buyoutNoteData = (note: string) => {
    return {
        note: note
    }
}

export interface IBuyoutTotalPrice {
    total_price: number
}

export const buyoutTotalPriceData = (total_price: number) => {
    return {
        total_price: total_price
    }
}

