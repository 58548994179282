import {axiosBase} from "../api/axios-base";
import {MUTATION_TYPES as MT} from "./_mutation-types";
import {ACTION_TYPES as AT} from "./action-types";
import {URLS} from "@/api/config";

export default {
    // user
    [AT.updateUserData](context, data) {
        context.commit(MT.SHOP_SET, data.userShop)
        context.commit(MT.CASH_REGISTER_SET, data.userCashRegister)
    },
    [AT.userTokens](context, credentials) {
        return axiosBase.post(URLS.token(), {
            username: credentials.username,
            password: credentials.password
        })
            .then(response => {
                context.commit(MT.TOKENS_SET, {
                    access: response.data.access,
                    refresh: response.data.refresh,
                })
            })

    },
    [AT.userCredentials](context, username) {
        return axiosBase.get(URLS.userCredentials(username), {headers: {Authorization: `Bearer ${context.state.accessToken}`}})
            .then(response => {
                context.commit(MT.CREDENTIALS_SET, {
                    username: username,
                    userShop: response.data.shop_identifier,
                    isAdmin: response.data.is_admin,
                    hasAllShops: response.data.has_all_shops,
                    userId: response.data.user_id,
                    userCashRegisterIdentifier: response.data.cash_register_identifier,
                    isGoldsmith: response.data.is_goldsmith
                })
            })

    },
    async [AT.login]({dispatch}, credentials) {
        const fetchTokens = await dispatch(AT.userTokens, credentials)
        const fetchCredential = await dispatch(AT.userCredentials, credentials.username)

        return Promise.all([fetchTokens, fetchCredential])
    },
    [AT.logout](context) {
        if (context.getters.isLoggedIn) {
            context.commit(MT.CREDENTIALS_TOKENS_REMOVE)
        }
        localStorage.removeItem('is_user_loaded')
    },

    // new buyout
    [AT.newBuyout](context) {

        context.commit(MT.BUYOUT_ITEMS_FORM_STATUS_UPDATE, false)
        context.commit(MT.BUYOUT_STATUS_UPDATE, false)
        context.commit(MT.CUSTOMER_FORM_STATUS_UPDATE, false)
        context.commit(MT.CUSTOMER_FORM_VISIBILITY_UPDATE, false)
        context.commit(MT.CONTRACT_PREVIEW_VISIBILITY_UPDATE, false)
        context.commit(MT.IS_EMAIL_UPDATE, true)
    },
    [AT.buyoutStatusUpdate](context) {
        context.commit(MT.BUYOUT_STATUS_UPDATE, true)
        context.commit(MT.CONTRACT_PREVIEW_VISIBILITY_UPDATE, true)
    },
    [AT.saveBuyoutItems](context) {
        context.commit(MT.CUSTOMER_FORM_VISIBILITY_UPDATE, true)
        context.commit(MT.BUYOUT_ITEMS_FORM_STATUS_UPDATE, true)

    },
    [AT.buyoutItemsUnsavedChange](context) {
        context.commit(MT.BUYOUT_ITEMS_FORM_STATUS_UPDATE, false)
    },
    [AT.saveCustomer](context) {
        context.commit(MT.CUSTOMER_FORM_STATUS_UPDATE, true)
    },
    [AT.customerUnsavedChange](context) {
        context.commit(MT.CUSTOMER_FORM_STATUS_UPDATE, false)
    },
    [AT.isEmailState](context, isEmail) {
        context.commit(MT.IS_EMAIL_UPDATE, isEmail)
    },

    // errors

    [AT.displayErrors](context, data) {
        context.commit(MT.ERRORS_STATE_UPDATE, {isActive: true, isNormalMessage: false, ...data})
    },
    [AT.hideErrors](context) {
        context.commit(MT.ERRORS_STATE_UPDATE, {
            isActive: false,
            errorMessage: null,
            many: null,
            isStringMessage: null,
            isNormalMessage: null
        })
    },
    [AT.displayMessage](context, data) {
        context.commit(MT.ERRORS_STATE_UPDATE, {isActive: true, isNormalMessage: true, ...data})

    }

}