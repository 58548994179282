import djangoJsReverse from 'django-js-reverse';
import getEnv from "./env";

const _urls = async () => {
    const res = await fetch(getEnv('VUE_APP_API_URL') + '/jsreverse.json');
    const data = await res.json();
    return djangoJsReverse(data);
}

export const urls = await _urls()


export const reverse = (url, args) => {
    return getEnv('VUE_APP_API_URL') + urls[url](...args)
}
