<template>
  <v-card color="basil">
    <v-stepper v-model="step" non-linear :key="!!batchStates.length">
      <v-stepper-header>

        <v-stepper-step v-for="batchState in batchStates" :key="batchState.identifier"
                        :complete="batchState > currentState"
                        :step="batchState.order"
                        @click.native="changeUrl(batchState.order)"
                        editable
        >
          <v-badge v-if="batchState.count" :content="batchState.count">
            {{ batchState.description }}
          </v-badge>
          <div v-else>
            {{ batchState.description }}
          </div>
        </v-stepper-step>

      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content :step="batchState.order" v-for="batchState in batchStates" :key="`${batchState.order}-step`">

          <batch-header v-if="batch" :batch="batch" :remaining="remaining"></batch-header>

          <div v-if="batchState.order==1">
            <batch-review-item-create :id="id" :batch="batch" @crateItem="handleItemCrate"></batch-review-item-create>
          </div>
          <batch-review-list
              :batch-items="batchItems"
              :state="step.toString()"
              :id="id"
              :is-complete="!!isComplete"
              :batch="batch"
              @updateItem="handleItemUpdate"
              @updateComplete="handleComplete"
              @reload="fetchBatchItems"
          >
          </batch-review-list>

        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-card>
</template>
<script>

import BatchReviewList from "@/components/batch/BatchReviewList.vue";
import {fetchBatch, fetchBatchItemList, fetchBatchStateList, updateBatchItemStates} from "@/components/batch/api";
import BatchReviewItemCreate from "@/components/batch/BatchReviewItemCreate.vue";
import BatchHeader from "@/components/batch/BatchHeader.vue";

export default {
  name: 'BatchReview',
  components: {BatchHeader, BatchReviewItemCreate, BatchReviewList},
  props: {
    id: {required: true, type: String},
    state: {required: true, type: String},
  },
  data() {
    return {
      batch: null,
      batchStates: [],
      batchItems: [],
      step: this.state,
    }
  },
  watch: {
    step() {
      this.fetchBatchItems().then(() => {
        this.fetchBatchState()
      })
    }
  },
  computed: {
    currentState() {
      let current = 1
      this.batchStates.forEach((item) => {
        if (item.count !== 0) {
          current = item.order
        }
      })
      return current
    },
    remaining() {
      let total = this.batch.weight ?? 0
      this.batchItems.forEach(i => {
        total -= i.weight ?? 0
      })
      return total
    },
    isAllFractions() {
      return this.batchItems.filter(i => i.type_jewel.identifier === "99").length === this.batchItems.length &&  this.batchItems.length
    },
    isComplete() {
      return !this.batch.reviewed && this.isAllFractions
    }
  },
  async mounted() {
    await this.getBatch()
    await this.fetchBatchState().then(() => {
      this.step = this.currentState
    })
  },
  methods: {
    handleItemCrate() {
      this.fetchBatchItems()
    },
    getBatch() {
      fetchBatch(this.id).then((response) => {
        this.batch = response.data
      })
    },
    async handleComplete(items) {
      await updateBatchItemStates(items, "4").then(() => {
        this.fetchBatchItems().then(() => {
          this.fetchBatchState()
        })
      })
      await this.getBatch()
    },
    async handleItemUpdate(items) {
      await updateBatchItemStates(items, this.state).then(() => {
        if (this.step !== 4) {
          this.step = this.step + 1
        } else {
          this.fetchBatchItems().then(() => {
            this.fetchBatchState()
          })
        }
        this.changeUrl(this.step)
      })
    },
    async changeUrl(state) {
      this.$router.push({
        name: 'BatchReview',
        params: {id: this.id, state: state.toString()}
      }).catch(() => {
      });
    },
    async fetchBatchItems() {
      await fetchBatchItemList(this.id, this.step).then(response => {
        this.batchItems = response.data.map((i) => ({action: "", ...i}))
      })
    },
    async fetchBatchState() {
      await fetchBatchStateList(this.id)
          .then(response => {
            this.batchStates = response.data
          })
    },
  }
}

</script>
