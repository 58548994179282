<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="440"
  >
    <template v-slot:activator="{on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs"></slot>
    </template>
    <v-card>
      <v-container>

        <v-card-title>
          <v-row>
            <v-col cols="12" md="9">
              <h2>Upravit</h2>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-autocomplete
                    :menu-props="{ offsetY: true }"
                    outlined
                    :items="items"
                    v-model="selectedItem"
                    label="Výkup"
                    validate-on-blur
                    :item-text="(value) => value.number"
                    :item-value="(value) => value.id"
                    clearable
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex flex-wrap mt-4">
          <v-btn class="aBtn--primaryInv flex-grow-1" @click="dialog=false">Ne</v-btn>
          <v-btn class="primary flex-grow-1" @click="confirmAction">Ano</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>

import rules from "@/utils/rules";
import {fetchBatchBuyouts, moveBatch, updateBatchItem} from "@/components/batch/api";

export default {
  name: 'DialogBatchItemEdit',
  computed: {
    rules() {
      return rules
    },
  },
  props: {
    item: {
      required: true, type: Object, default: () => {
      },
    },
  },
  async mounted() {
    this.fetchData()
  },
  data() {
    return {
      search: "19",
      isLoading: false,
      selectedItem: null,
      items: [],
      dialog: false,
      delayTimer: 500
    }
  },
  methods: {
    fetchData() {
      fetchBatchBuyouts(this.item.batch.id).then((response) => {
        this.items = response.data
      })
    },
    reset() {
      this.search = ""
    },
    confirmAction() {
      updateBatchItem(this.item.id, {"buyout": this.selectedItem}).then(_ => {
        this.dialog = false
        this.$emit("update")
      })
      this.reset()
    }
  }

}

</script>