export const ACTION_TYPES = {
    // user
    updateUserData:'UPDATE_SHOP',
    userTokens:'USER_TOKENS',
    userCredentials:'USER_CREDENTIALS',
    login:'LOGIN',
    logout:'LOGOUT',

    // new buyout
    newBuyout: 'NEW_BUYOUT',
    buyoutStatusUpdate: 'BUYOUT_STATUS_UPDATE',
    saveBuyoutItems:'SAVE_COMMODITIES',
    buyoutItemsUnsavedChange: 'COMMODITIES_UNSAVED_CHANGE',
    saveCustomer: 'SAVE_CUSTOMER',
    customerUnsavedChange: 'CUSTOMER_UNSAVED_CHANGE',
    isEmailState: 'IS_EMAIL_STATE',

    // errors
    displayErrors: 'DISPLAY_ERRORS',
    hideErrors: 'HIDE_ERRORS',
    displayMessage: 'DISPLAY_MESSAGE'

}