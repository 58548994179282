<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="440"
  >
    <template v-slot:activator="{on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs"></slot>
    </template>
    <v-card>
      <v-container>

        <v-card-title>
          <v-row>
            <v-col cols="12" md="9">
              <h2> Přsunout várky</h2>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-autocomplete
                    :menu-props="{ offsetY: true }"
                    outlined
                    v-model="selectedItem"
                    :items="items"
                    :loading="isLoading"
                    :search-input.sync="search"
                    item-text="text"
                    item-value="value"
                    label="Císlo várky"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex flex-wrap mt-4">
          <v-btn class="aBtn--primaryInv flex-grow-1" @click="dialog=false">Ne</v-btn>
          <v-btn class="primary flex-grow-1" @click="confirmAction">Ano</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>

import rules from "@/utils/rules";
import {fetchBatchList, moveBatch} from "@/components/batch/api";

export default {
  name: 'DialogBatchMove',
  computed: {
    rules() {
      return rules
    },
    selectedIds() {
      return this.selected.map(i => i.id)
    }
  },
  props: {
    selected: {required: true, type: Array},
    msg: {required: false, type: String, default: ''}
  },
  watch: {
    search: {
      handler() {
        this.fetchData()
      },
    },
    selected: {
      handler() {
        this.fetchData()
      },
    },
  },
  data() {
    return {
      search: "",
      isLoading: false,
      selectedItem: null,
      items: [],
      dialog: false,
      delayTimer: 500
    }
  },
  methods: {
    fetchData() {
      fetchBatchList({search: this.search, deleted: false}).then(response => {
        this.items = response.data.results.filter(i => !this.selectedIds.includes(i.id)).map(i => {
          return {
            value: i.id,
            text: `${i.identifier} | ${i.type_batch?.description || ""} - ${i.type_commodity?.description || ""} - ${i.commodity_purity?.purity || ""}`,
          }
        })
      })
    },
    reset() {
      this.search = ""
      this.selectedItem = null
    },
    confirmAction() {
      moveBatch(this.selectedItem, this.selected).then(_ => {
        this.dialog = false
        this.$emit("update")
      })
      this.reset()
    }
  }

}

</script>