<template>
  <v-form class="px-4 my-4" @submit.prevent="handleSave">
    <v-row>
      <v-col
          cols="12"
          md="5"
      >
        <v-autocomplete
            :menu-props="{ offsetY: true }"
            outlined
            :items="jewleTypes"
            v-model="formData.type_jewel"
            label="Typ Šperků"
            validate-on-blur
            :rules="[rules.required]"
            :item-text="(value) => value.description"
            :item-value="(value) => value.id"
            :disabled="!canEdit"
        >
          <template v-slot:item="{  item }">
            {{ item.description }} ({{ item.identifier }})
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
          cols="12"
          md="5"
      >
        <v-text-field
            outlined
            label="Popis"
            validate-on-blur
            v-model="formData.description"
            :disabled="!canEdit"
        />
      </v-col>
      <v-col cols="0" md="2" class="pa-0"></v-col>
      <v-col
          cols="12"
          md="5"
          class="d-flex ga-4"
      >
        <v-text-field
            outlined
            label="Hmotnost"
            :rules="[rules.required, rules.isValidDecimal]"
            validate-on-blur
            v-model="formData.weight"
            :min="0"
            type="number"
            :disabled="!canEdit"
        >
          <template v-slot:append>
            <v-tooltip top>
              <template v-slot:activator="{on}">
                <v-icon @click="appendMaxWeight" v-on="on">mdi-weight</v-icon>
              </template>
              <span>Maximální hmotnost</span>
            </v-tooltip>
          </template>
        </v-text-field>
        <v-btn large class="primary" @click="getWeight()" :disabled="!isSerialPortApiAvailable()">
          Zvážit
        </v-btn>
      </v-col>
      <v-col
          cols="12"
          md="5"
      >
        <v-file-input
            class="goldsmithDetail__imageInput"
            v-model="image"
            :rules="imageRules"
            validate-on-blur
            label=""
            prepend-icon="mdi-upload"
            hide-input
            ref="fileInput"
        >
          <template v-slot:append-outer>
            <span :class="isError && !image ? ['errorColor']:[]">{{ imageText }}</span>
          </template>
        </v-file-input>
      </v-col>
      <v-col
          cols="12"
          md="2"
      >
        <v-btn type="submit" large block class="primary" :disabled="!canEdit">Uložit</v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import rules from '@/utils/rules.js'

import {
  batchBuyoutBatchItemJewelCreate,
  fetchJewelTypeList,
  uploadImage
} from "@/components/batch/api";
import {getSerialPortData, isSerialPortApiAvailable} from "@/utils/serialport";

export default {
  name: 'BuyoutBatchItemJewelCreate',
  props: {
    id: {required: true, type: String},
    buyout: {
      required: true, type: Object, default: () => {
      }
    }
  },
  data() {
    return {
      loading: false,
      isError: false,
      formData: {
        id: undefined,
        description: undefined,
        weight: 0,
        type_jewel: undefined,
      },
      rules: {
        ...rules
      },
      jewleTypes: [],
      qrValue: undefined,
      batchItem: undefined,
      image: undefined,
    }
  },
  computed: {
    canEdit() {
      return true
    },
    imageText() {
      if (this.image) {
        return "Obrázek - Vloženo"
      }
      return "Obrázek - Nevloženo"
    },
    imageRules() {
      return []
    }
  },
  async mounted() {
    this.loading = true
    await this.getJewelList()
    this.batchItem = this.item
    this.loading = false
  },
  methods: {
    getJewelList() {
      fetchJewelTypeList().then((response) => {
        this.jewleTypes = response.data
      })
    },
    isSerialPortApiAvailable,
    async appendMaxWeight() {
      this.formData.weight = this.item.weight_left
    },
    async handleCreate() {
      await batchBuyoutBatchItemJewelCreate(this.id, {
        description: this.formData.description,
        weight: this.formData.weight,
        type_jewel: this.formData.type_jewel,
        buyout: this.buyout.id
      }).then(response => {
        let data = new FormData()
        data.append('image', this.image)
        uploadImage(response.data.id, data).then(() => {
          this.$emit('create')
        })
      })
    },
    handleSave() {
      this.handleCreate().then(() => {
        this.resetForm()
      })
    },
    resetForm() {
      this.formData = {
        id: undefined,
        description: undefined,
        weight: undefined,
        type_batch: undefined,
      }
      this.image = undefined
    },
    getWeight() {
      //TODO implement serial port weight  https://developer.mozilla.org/en-US/docs/Web/API/SerialPort
      getSerialPortData().then(value => {
        this.formData.weight = value
      })
    }
  },
  watch: {}
}

</script>