import {axiosBase} from "@/api/axios-base";
import {IUpdateUserData} from "./config";
import {URLS} from "@/api/config";


export const fetchShops = async () => {
    return await axiosBase.get(URLS.shopsList(), )
}

export const updateUserInfo = async (username:string, data:IUpdateUserData) => {
    return await axiosBase.put(URLS.userUpdate(username),data,)
}

export const fetchCashRegisters = async () => {
    return await axiosBase.get(URLS.cashRegistersAllowedOnly())
}