<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="440"
  >
    <template v-slot:activator="{on, attrs }">
     <slot name="activator" :on="on" :attrs="attrs"></slot>
    </template>
    <v-card>
      <v-container>
        <v-card-title class="text-h5 text-center px-12">
          {{msg}}
        </v-card-title>
        <v-card-actions class="d-flex flex-wrap mt-4">
          <v-btn class="aBtn--primaryInv flex-grow-1" @click="dialog=false">Ne</v-btn>
          <v-btn class="primary flex-grow-1" @click="confirmAction">Ano</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'ConfirmPopup',
  props: {
    confirmFunction: {required: true, type: Function},
    msg: {required: false, type:String, default:'' }
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    confirmAction() {
      this.confirmFunction()
      this.dialog = false
    }
  }

}

</script>