<template>
  <v-card>
    <v-card-title>Aktuálně zpracovávaná část</v-card-title>
    <v-form v-if="!loading" class="px-4" @submit.prevent="handleUpdate" ref="goldsmithForm">
      <v-row>
        <!--        <v-col-->
        <!--            cols="12"-->
        <!--            md="2">-->
        <!--          <v-text-field-->
        <!--              outlined-->
        <!--              disabled-->
        <!--              v-model="formData.type_commodity"-->
        <!--              :rules="[rules.required]"-->
        <!--              validate-on-blur-->
        <!--              label="Typ komodity"/>-->
        <!--        </v-col>-->
        <!--        <v-col-->
        <!--            cols="12"-->
        <!--            md="2">-->
        <!--          <v-text-field-->
        <!--              outlined-->
        <!--              disabled-->
        <!--              v-model="formData.purity"-->
        <!--              :rules="[rules.required]"-->
        <!--              validate-on-blur-->
        <!--              label="Ryzost"/>-->
        <!--        </v-col>-->
        <v-col
            cols="12"
            md="2"
        >
          <v-autocomplete
              :menu-props="{ offsetY: true }"
              outlined
              :items="typeBranchesOfGoods"
              v-model="formData.type_branch_of_good"
              label="Obor zboží"
              validate-on-blur
              :rules="[rules.required]"
              :item-text="(value) => value.description"
              :item-value="(value) => value"
          />
        </v-col>
        <v-col
            cols="12"
            md="2"
        >
          <v-autocomplete
              :menu-props="{ offsetY: true }"
              outlined
              :items="getFilteredSubtypeBranchesOfGoods()"
              v-model="formData.subtype_branch_of_good"
              label="Podobor zboží"
              validate-on-blur
              :rules="[rules.required]"
              :item-text="(value) => value.description"
              :item-value="(value) => value"
              :disabled="!this.formData.type_branch_of_good"
          />


        </v-col>
        <v-col
            cols="12"
            md="2"
        >
          <v-text-field
              outlined
              label="Hmotnost"
              :rules="[rules.required]"
              validate-on-blur
              v-model="formData.weight"
          >
            <template v-slot:append>
              <v-tooltip top>
                <template v-slot:activator="{on}">
                  <v-icon @click="appendMaxWeight" v-on="on">mdi-weight</v-icon>
                </template>
                <span>Maximální hmotnost</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
        <v-col
            cols="12"
            md="2"
        >
          <v-text-field
              outlined
              label="Popis"
              validate-on-blur
              v-model="formData.description"
          />
        </v-col>
        <v-col
            cols="12"
            md="3"
        >
          <v-file-input
              class="goldsmithDetail__imageInput"
              v-model="formData.image"
              :rules="imageRules"
              validate-on-blur
              label=""
              prepend-icon="mdi-upload"
              hide-input
              ref="fileInput"
          >
            <template v-slot:append-outer>
              <span :class="isError && !formData.image ? ['errorColor']:[]">{{ imageText }}</span>
            </template>
          </v-file-input>

        </v-col>
      </v-row>
      <v-row>
        <v-col
            cols="12"
            md="2"
        >
          <v-btn type="submit" small block class="primary">Uložit</v-btn>
        </v-col>
        <v-col
            cols="12"
            md="2"
        >
          <v-btn @click="handleCancel" small block class="text-button">Zrušit</v-btn>
        </v-col>
      </v-row>
    </v-form>

  </v-card>
</template>

<script>
import rules from '@/utils/rules.js'
import {
  fetchGoldsmithItemDetail,
  fetchSubtypesBranchesOfGoods,
  fetchTypesBranchesOfGoods, getMaxWeight,
  updateGoldsmithItem, uploadImage
} from "./api";
import {MUTATION_TYPES as MT} from "../../store/_mutation-types";
import {ACTION_TYPES as AT} from "../../store/action-types";

export default {
  name: 'GoldsmithItemEditForm',
  props: {
    buyoutId: {required: true, type: String}
  },
  data() {
    return {
      loading: false,
      isError: false,
      formData: {
        id: undefined,
        description: undefined,
        weight: undefined,
        purity: undefined,
        type_commodity: undefined,
        type_branch_of_good: undefined,
        subtype_branch_of_good: undefined,
        image: undefined,
      },

      rules: {
        ...rules
      },
      typeBranchesOfGoods: [],
      subtypeBranchesOfGoods: [],
    }
  },
  computed: {
    activeItem() {
      return this.$store.state.activeGoldsmithItem
    },
    imageText() {
      if (this.formData.image) {
        return "Obrázek - Vloženo"
      }
      return "Obrázek - Nevloženo"
    },
    imageRules() {
      if (this.formData.type_branch_of_good) {
        return this.formData.type_branch_of_good.identifier !== 'fractions' ? [this.rules.required] : []
      }
      return []
    }
  },
  async mounted() {
    this.loading = true
    await this.getBranchesOfGoods()
    await this.getData()
    this.loading = false
  },
  methods: {
    async getBranchesOfGoods() {
      await fetchTypesBranchesOfGoods()
          .then(response => {
            this.typeBranchesOfGoods = response.data
          })
      await fetchSubtypesBranchesOfGoods()
          .then(response => {
            this.subtypeBranchesOfGoods = response.data
          })
    },

    async getData() {
      await fetchGoldsmithItemDetail(this.activeItem)
          .then(response => {
            this.formData = response.data
          })
    },
    handleUpdate() {
      this.isError = false
      if (this.$refs.goldsmithForm.validate()) {
        updateGoldsmithItem(this.activeItem, {
          description: this.formData.description,
          weight: this.formData.weight,
          type_branch_of_good: this.formData.type_branch_of_good.id,
          subtype_branch_of_good: this.formData.subtype_branch_of_good.id,
          finished: true,
        })
            .then(() => {
              let data = new FormData()
              data.append('image', this.formData.image)
              uploadImage(this.activeItem, data)
                  .then(() => {
                    this.$store.commit(MT.GOLDSMITH_ACTIVE_ITEM_REMOVE)
                    this.$store.dispatch(AT.displayMessage, {
                      errorMessage: 'Uloženo',
                      many: false,
                      isStringMessage: true
                    })
                  })

            })
      } else {
        this.isError = true
      }
    },

    handleCancel() {
      this.$store.commit(MT.GOLDSMITH_ACTIVE_ITEM_REMOVE)
    },
    getFilteredSubtypeBranchesOfGoods() {
      if (this.formData.type_branch_of_good) {
        let subtypesList = []
        this.subtypeBranchesOfGoods.forEach(value => {
          if (value.parent === this.formData.type_branch_of_good.id) {
            subtypesList.push(value)
          }
        })
        return subtypesList
      }
      return []
    },
    appendMaxWeight() {
      getMaxWeight(this.formData.id)
          .then(response => {
            this.formData.weight = response.data.max_weight
          })
    },
    selectFile() {
      let fileInputElement = this.$refs.fileInput
      fileInputElement.click()
    }

  },
  watch: {
    activeItem: {
      handler() {
        this.getData()
      }
    }
  }
}

</script>