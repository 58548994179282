import axios from "axios"
import router from "../router"
import authHeaders from "@/api/authHeaders";
import store from '@/store'
import {ACTION_TYPES as AT} from "@/store/action-types";
import getEnv from "@/utils/env";

export const BASE_API_URL = getEnv('VUE_APP_API_URL')

export const axiosBase = axios.create({
    baseURL: getEnv('VUE_APP_API_URL'),
    headers: {
        contentType: 'application/json',
        ...authHeaders()
    }
})

axiosBase.interceptors.response.use(undefined, (error) => {
    // handle 401 not authorized
    if (error.config && error.response && error.response.status === 401) {
        router.push({name: "TimeOut"})
        // handle other errors
    } else {
        // if error has status code
        if (error.response !== undefined) {
            // handle 500 separately
            if (error.response.status === 500) {
                store.dispatch(AT.displayErrors, {
                    errorMessage: '500 Internal server error',
                    many: false,
                    isStringMessage: true
                })
            }
            // ignore 404
            else if (error.response.status !== 404) {
                store.dispatch(AT.displayErrors, {
                    errorMessage: error.response.data,
                    many: true,
                    isStringMessage: false
                })
            }
            // if BE doesn't respond
        } else {
            store.dispatch(AT.displayErrors, {errorMessage: error.message, many: false, isStringMessage: true})
        }
    }
    return Promise.reject(error)

})