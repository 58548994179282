<template>
  <v-form class="px-4" @submit.prevent="handleSave">
    <v-row>
      <v-col
          cols="12"
          md="2"
      >
        <v-autocomplete
            :menu-props="{ offsetY: true }"
            outlined
            :items="batchTypes"
            v-model="formData.type_batch"
            label="Typ"
            validate-on-blur
            :rules="[rules.required]"
            :item-text="(value) => value.description"
            :item-value="(value) => value.id"
        />
      </v-col>

      <v-col
          cols="12"
          md="2"
      >
        <v-select
            v-model="formData.type_commodity"
            :items="typeCommodityList"
            label="Typ komodity"
            :menu-props="{ offsetY: true }"
            outlined
            validate-on-blur
            :rules="[rules.required]"
            :item-text="(value) => `${value.description}`"
            :item-value="(value) => value.id"
        />
      </v-col>

      <v-col
          cols="12"
          md="2"
      >
        <v-select
            v-model="formData.commodity_purity"
            :items="getFilteredCommodityPurities(formData.type_commodity)"
            label="Ryzost"
            :menu-props="{ offsetY: true }"
            outlined
            validate-on-blur
            :rules="[rules.required]"
            :item-text="(value) => `${getFormattedPurityText(value)}`"
            :item-value="(value) => value.id"
        />
      </v-col>

      <v-col
          cols="12"
          md="2"
      >
        <v-text-field
            outlined
            label="Hmotnost"
            :rules="[rules.required]"
            validate-on-blur
            v-model="formData.weight"
            :max="this.weightLeft"
            :min="0"
            type="number"
        >
          <template v-slot:append>
            <v-tooltip top>
              <template v-slot:activator="{on}">
                <v-icon @click="appendMaxWeight" v-on="on">mdi-weight</v-icon>
              </template>
              <span>Maximální hmotnost</span>
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
      <v-col
          cols="12"
          md="1"
      >
        <v-btn large class="aBtn--primaryInv" @click="getWeight()" :disabled="!isSerialPortApiAvailable()">
          Zvážit
        </v-btn>
      </v-col>
      <v-col
          cols="12"
          md="1"
      >
        <v-btn type="submit" large block class="primary">Uložit</v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import rules from '@/utils/rules.js'

import {
  createBuyoutBatchItem,
  fetchBatchTypeList
} from "@/components/batch/api";
import {getSerialPortData, isSerialPortApiAvailable} from "@/utils/serialport";
import {fetchCommodityPurities, fetchCommodityTypes} from "@/components/newBuyout/api";
import {formattedPrice} from "@/utils/formatters";

export default {
  name: 'BuyoutBatchItemCreate',
  props: {
    item: {
      required: false, type: Object, default: () => {
      }
    }
  },
  data() {
    return {
      loading: false,
      isError: false,
      formData: {
        id: undefined,
        weight: undefined,
        type_batch: undefined,
        type_commodity: undefined,
        commodity_purity: undefined,
      },
      rules: {
        ...rules
      },
      batchTypes: [],
      batchItem: undefined,
      typeCommodityList: [],
      commodityPurities: []
    }
  },
  computed: {
    weightLeft() {
      return this.item ? this.item.weight_left : null
    },
  },
  async mounted() {
    this.loading = true
    await this.fetchBatchTypes()
    await this.fetchCommodityTypes()
    await this.fetchCommodityPurities()
    this.batchItem = this.item
    this.loading = false
  },
  methods: {
    isSerialPortApiAvailable,
    async appendMaxWeight() {
      this.formData.weight = this.item.weight_left
    },
    async fetchBatchTypes() {
      await fetchBatchTypeList().then(response => {
        this.batchTypes = response.data
      })
    },
    async fetchCommodityTypes() {
      await fetchCommodityTypes().then((response) => {
        this.typeCommodityList = response.data
      })
    },
    async fetchCommodityPurities() {
      await fetchCommodityPurities().then((response) => {
        this.commodityPurities = response.data
      })
    },
    async handleCreate(item) {
      createBuyoutBatchItem(item ? item.id : '', this.formData).then(() => {
        this.$emit('crateItem')
      })
    },
    getFilteredCommodityPurities(commodity_id) {
      if (commodity_id === null) {
        return []
      }
      let filtered_list = []
      this.commodityPurities.forEach(value => {
        if (value.type_commodity === commodity_id) {
          filtered_list.push(value)
        }
      })
      return filtered_list
    },
    handleSave() {
      this.handleCreate(this.item).then(() => {
        this.resetForm()
      })
    },
    resetForm() {
      this.formData = {
        id: undefined,
        description: undefined,
        weight: undefined,
        type_batch: undefined,
        image: undefined,
        type_commodity: undefined,
        commodity_purity: undefined,
      }
    },
    getWeight() {
      //TODO implement serial port weight  https://developer.mozilla.org/en-US/docs/Web/API/SerialPort
      return getSerialPortData()
    },
    subtractPercentDeviation(commodityPurityItem) {
      return commodityPurityItem.price_for_gram * (1 + (commodityPurityItem.price_deviation_percent / 100))
    },
    getFormattedPurityText(commodityPurityItem) {
      return `${commodityPurityItem.purity} | ${formattedPrice(this.subtractPercentDeviation(commodityPurityItem))} Kč `
    }
  },
  watch: {}
}

</script>