<template>
     <v-card>
      <div>
        <v-data-table
            :headers="headers"
            :items="batch_items"
            class="elevation-2"
            :hide-default-footer="true"
            disable-pagination
        >
          <template #no-data>
            Žádná data...
          </template>
          <template #loading>
            Načítání dat...
          </template>
        </v-data-table>
      </div>
    </v-card>
</template>
<script>

import {
  fetchBatchItemsAssignedList
} from "./api";

export default {
  name: 'BatchCreateList',
  components: {},
  props: {
    batch: {
      type: Object, required: false, default: function () {
        return {id: "", finished: false}
      }
    },
  },
  watch: {
    batch: function () {
      this.loadData()
    }
  },
  data() {
    return {
      expanded: [],
      loading: false,
      headers: [
        {text: '', value: 'finished'},
        {text: 'Typ', align: 'start', value: 'type_batch.description'},
        {text: 'Celková hmotnost (g)', value: 'weight'},
        {text: 'Popís', value: 'description'},
        {text: 'Obrázek', value: 'image'},
        {text: '', value: 'sortButton', sortable: false}
      ],
      batch_items: [],
    }
  },
  async mounted() {
    await this.loadData()
  },
  methods: {
    async loadData() {
      this.loading = true
      await this.fetchItems()
      this.loading = false
    },
    async fetchItems() {
      await fetchBatchItemsAssignedList(this.batch.id).then(response => {
        this.batch_items = response.data
      })
    },
  }
}

</script>
