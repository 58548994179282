<template>
  <v-card>
    <v-card-title>Zpracované předměty</v-card-title>
    <v-data-table
        :headers="headers"
        :items="goldsmithItems"
        class="elevation-2"
        :hide-default-footer="true"
        disable-pagination
    >
      <template #[`item.image`]="{item}">
        <v-btn v-if="item.image" x-small @click="openImage(item)" class="primary">
          Obrázek</v-btn>
      </template>

      <template #[`item.actions`]="{ item }">
        <v-icon class="mx-1"
                small
                @click="handleItemEdit(item)">
          mdi-pencil
        </v-icon>
        <DeletePopup class="mx-1" :delete-function="() => handleItemDelete(item)" msg="Opravdu si přejete smazat tento předmět?"/>
      </template>
      <template #no-data>
        Žádná data...
      </template>
      <template #loading>
        Načítání dat...
      </template>
    </v-data-table>

  </v-card>
</template>

<script>

import {destroyGoldsmithItem, fetchGoldsmithItems} from "./api";
import {MUTATION_TYPES as MT} from "../../store/_mutation-types";
import DeletePopup from "../utils/DeletePopup";

export default {
  name: 'GoldsmithItemsList',
  props: {
    buyoutId: {required: true, type: String}
  },
  data() {
    return {
      headers: [
        {text: 'Obor zboží', align: 'start', value: 'type_branch_of_good.description'},
        {text: 'Podobor zboží', value: 'subtype_branch_of_good.description'},
        {text: 'Typ komodity', value: 'type_commodity'},
        {text: 'Rizost předmětu', value: 'purity'},
        {text: 'Hmotnost předmětu', value: 'weight'},
        {text: 'Obrázek', value: 'image'},
        {text: 'Popis předmětu', value: 'description'},
        {text: '', value: 'actions', sortable: false}
      ],
      goldsmithItems: [],
    }
  },
  async mounted() {
    this.$store.watch((state) => {
          return state.activeGoldsmithItem
        },
        () => {
          this.getData()
        },
        {deep: true})

    await this.getData()
  },
  methods: {
    async getData() {
      await fetchGoldsmithItems(this.buyoutId)
          .then(response => {
            this.goldsmithItems = response.data
          })
      let allGoldsmithItemsAreOk = true
      this.goldsmithItems.forEach(value => {
        if (!value.finished) {
          allGoldsmithItemsAreOk = false
        }
      })
      this.$store.commit(MT.ALL_GOLDSMITH_ITEMS_ARE_OK_UPDATE, allGoldsmithItemsAreOk)
    },
    handleItemEdit(item) {
      this.$store.commit(MT.GOLDSMITH_ACTIVE_ITEM_UPDATE, item.id)
    },
    handleItemDelete(item) {
      destroyGoldsmithItem(item.id)
          .then(() => {
            this.getData()
            if (this.$store.state.activeGoldsmithItem === item.id) {
              this.$store.commit(MT.GOLDSMITH_ACTIVE_ITEM_REMOVE)
            } else {
              this.$store.commit(MT.GOLDSMITH_ACTIVE_ITEM_UPDATE, 'item to watch')
              this.$store.commit(MT.GOLDSMITH_ACTIVE_ITEM_REMOVE)
            }
          })
    },
    openImage(item){
      window.open(item.image, '_blank')
    }
  },
  components: {
    DeletePopup
  }
}

</script>
