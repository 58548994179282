<template>
  <div>
    <v-row>
      <v-col cols="12" md="12">
        <v-row>
          <v-col cols="12" md="3" class="pl-6">
            <b class="pl-4">Čislo várky:</b> {{ batch.identifier }}
          </v-col>
          <v-col cols="12" md="3" class="pl-6">
            <b class="pl-4">Položka:</b> {{ batch.type_batch.description }}
          </v-col>
        </v-row>

      </v-col>
      <v-col cols="12" md="9" class="pl-6">
        <v-row>
          <v-col cols="12" md="4" class="pl-6">
            {{ batch.commodity_purity.full_description }}
          </v-col>
          <v-col cols="12" md="4">
            {{ batch.weight }} g
            <span :class="remaining >= 0 ? 'gLabel gLabel--success' : 'gLabel gLabel--error'">{{
                remaining || 0
              }} g </span>
          </v-col>
          <v-col cols="12" md="2">
            {{ batch.description }}
          </v-col>
          <v-col cols="12" md="2">
            <div
                v-if="batch.reviewed"
                class="gLabel gLabel--success"
            >
              <v-icon small>$circle-check</v-icon>
              Dokončeno
            </div>
            <div
                v-else
                class="gLabel gLabel--error"
            >
              <v-icon small>$circle-exclamation</v-icon>
              Nedokončeno
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="3" class="px-6 pb-8">
        <dialog-batch-review-move :batch="batch">
          <template #activator="{on}">
            <v-btn v-on="on" class="primary d-block ml-auto" large>Přesunout</v-btn>
          </template>
        </dialog-batch-review-move>
      </v-col>
    </v-row>
    <v-divider/>
  </div>
</template>

<script>
import DialogBatchReviewMove from "@/components/batch/DialogBatchReviewMove.vue";

export default {
  name: 'BatchHeader',
  components: {DialogBatchReviewMove},
  props: {
    batch: {
      required: false, type: Object, default: () => {
      },
    },
    remaining: {
      required: false, type: Number, Number: 0
    }
  }
}

</script>