import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import * as Sentry from "@sentry/vue";

import './styles/_entry.scss'

Vue.config.productionTip = false


if (
    process.env.NODE_ENV === 'production' ||
    process.env.NODE_ENV === 'staging'
) {

    Sentry.init({
        Vue,
        dsn: "https://9edee88d2b2f436787c9dc0bbc214f04@sentry.olc.cz/103",
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            new Sentry.Replay(),
        ],
        environment: process.env.NODE_ENV,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.1,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.01,
        replaysOnErrorSampleRate: 1.0,
    });
}
new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
