export interface IBuyoutPreview {
    id: string,
    customer: string,
    total_price: number,
    num_commodities: number,
    type_status: {
        identifier: string,
        description: string
    },
    finished_date: string,
    shop: {
        identifier: string,
        description: string
    },
    exported_to_BOSS:boolean
}

export interface IBuyoutListResponse {
    count: number,
    next: string | null,
    previous: string | null,
    results: Array<IBuyoutPreview>
}


export interface IQueryParams {
    page: number,
    page_size: number,
    ordering?: string,
    desc?: number,
    statuses: string,
    search?: string,
    date_from: string,
    date_to: string,
    shops: string,
}

export interface ITypeStatus {
    identifier: string,
    description: string
}

export const goldsmithStatuses = [
    {
        identifier: 'redeemed',
        description: 'Vykoupeno'
    },
    {
        identifier: 'sorting',
        description: 'Třídění'
    },
]

export interface ITypeShop {
    identifier: string,
    description: string
}