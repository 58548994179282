<template>
 <div>
   <PasswordChange class="mx--center mt-5"/>
 </div>
</template>

<script>
import PasswordChange from "../../components/account/PasswordChange";

export default {
  name: "App.vue",
  components: {PasswordChange}
}
</script>
