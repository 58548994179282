<template>
  <div class="ma-0 pa-0 buyoutsList__container">
  <buyout-process-list></buyout-process-list>
  </div>
</template>

<script>



import BuyoutProcessList from "@/components/batch/BuyoutProcessList.vue";
export default {
  name: 'buyoutProcess',
  components: {BuyoutProcessList}
}

</script>