import {axiosBase} from "@/api/axios-base";
import {URLS} from "@/api/config";
import {AxiosResponse} from "axios";
import {
    IBuyoutItem,
    IGoldsmithItem,
    IGoldsmithItemCreateResponse,
    IGoldsmithItemUpdateData, IMaxWeight, ISubtypeBranchOfGood, ITypeBranchOfGood
} from "@/components/goldsmithDetail/config";
import {goldsmithStatuses} from "@/components/buyouts/config";

export const fetchBuyoutItems = (buyoutId: string): Promise<AxiosResponse<Array<IBuyoutItem>>> => {
    return axiosBase.get(URLS.gBuyoutItemsList(buyoutId))
}

export const createGoldsmithItem = (buyoutId: string, buyoutItemId: string): Promise<AxiosResponse<IGoldsmithItemCreateResponse>> => {
    return axiosBase.get(URLS.createGoldsmithItem(buyoutId, buyoutItemId))
}

export const fetchGoldsmithItems = (buyoutId: string): Promise<AxiosResponse<Array<IGoldsmithItem>>> => {
    return axiosBase.get(URLS.goldsmithItemsList(buyoutId))
}

export const fetchGoldsmithItemDetail = (goldsmithItemId: string): Promise<AxiosResponse<IGoldsmithItem>> => {
    return axiosBase.get(URLS.goldsmithItemDetail(goldsmithItemId))
}

export const destroyGoldsmithItem = (goldsmithItemId: string): Promise<null> => {
    return axiosBase.delete(URLS.goldsmithItemDestroy(goldsmithItemId))
}

export const updateGoldsmithItem = (goldsmithItemId: string, data: IGoldsmithItemUpdateData): Promise<AxiosResponse<IGoldsmithItem>> => {
    return axiosBase.put(URLS.goldsmithItemUpdate(goldsmithItemId), data)
}

export const fetchTypesBranchesOfGoods = (): Promise<AxiosResponse<Array<ITypeBranchOfGood>>> => {
    return axiosBase.get(URLS.typeBranchesOfGoodsList())
}

export const fetchSubtypesBranchesOfGoods = (): Promise<AxiosResponse<Array<ISubtypeBranchOfGood>>> => {
    return axiosBase.get(URLS.subtypeBranchesOfGoodsList())
}

export const getMaxWeight = (goldsmithItemId: string): Promise<AxiosResponse<IMaxWeight>> => {
    return axiosBase.get(URLS.goldsmithItemMaxWeight(goldsmithItemId))
}

export const uploadImage = (goldsmithItemId: string, data: unknown): Promise<null> => {
    return axiosBase.post(URLS.goldsmithItemUploadImage(goldsmithItemId), data, {headers:{'Content-Type': 'multipart/form-data'}})
}