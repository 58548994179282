<template>
  <div>
    <v-card>
      <v-card-title>
        <v-container class="mb-5 pb-0">
          <v-row>
            <DialogBatchMove :selected="selected" @update="getData()">
              <template #activator="{on}">
                <v-btn v-on="on" class="primary" min-width="250" :disabled="!selected.length">Přesunout</v-btn>
              </template>
            </DialogBatchMove>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="aBtn--primaryInv ml-auto"
                    icon
                    @click="showFilters = !showFilters"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <span>Zobrazit filtry</span>
            </v-tooltip>
          </v-row>
          <v-row v-if="showFilters">
            <v-col cols="12">
              <v-divider/>
            </v-col>
            <v-col cols="12" class="pt-0">
              <v-checkbox
                  v-model="search.buyout"
                  label="Výkup"
                  class="mt-2"
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                  outlined
                  light
                  :value="formattedDate(search.dateFrom)"
                  class="formInput--datePicker"
                  label="Datum od..."
                  :readonly="true"
                  validate-on-blur
              >
                <template #append>
                  <v-icon @click="search.dateFrom = '' ">mdi-minus-circle</v-icon>
                  <DatePicker v-model="search.dateFrom"/>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                  outlined
                  light
                  :value="formattedDate(search.dateTo)"
                  class="formInput--datePicker"
                  label="Datum do..."
                  :readonly="true"
                  validate-on-blur
              >
                <template #append>
                  <v-icon @click="search.dateTo = '' ">mdi-minus-circle</v-icon>
                  <DatePicker v-model="search.dateTo"
                              :showCurrent="search.dateFrom !== '' ? search.dateFrom:undefined"/>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="d-flex ga-4">
              <v-text-field
                  prepend-inner-icon="mdi-magnify"
                  label="Vyhledat..."
                  outlined
                  v-model="search.fulltext"
                  @keydown.enter="handleSearch"
              >
                <template #append>
                  <v-tooltip top>
                    <template #activator="{on}">
                      <v-icon v-on="on">mdi-information-outline</v-icon>
                    </template>
                    <span>Pro vyhledávání stiskněte enter</span>
                  </v-tooltip>
                </template>
              </v-text-field>
              <v-btn class="primary" large @click="handleSearch()">Vyhledat</v-btn>
              <v-btn class="aBtn--primaryInv" large @click="handleResetSearch()">Výchozí</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-data-table
          v-model="selected"
          :headers="headers"
          :items="batches"
          :loading="grid.loading"
          :options.sync="options"
          :server-items-length="this.grid.count"
          :items-per-page="grid.defaultPageSize"
          class="vDataTable--dense elevation-2"
          :footer-props="{
            'items-per-page-text':'Počet výkupů na stránce',
            'items-per-page-options':grid.itemsPerPageOptions,
            'rowsPerPage':grid.defaultPageSize
          }"
          :show-select="true"
          show-expand
          :expanded.sync="expanded"
          :item-class="row_classes"
      >
        <template v-slot:expanded-item="{headers,  item }">
          <td :colspan="headers.length">
            <v-simple-table dense>
              <template #default>
                <tbody>
                <tr v-for="(buyout, i) in item.buyouts" :key="i">
                  <td>
                    <span style="padding: 0 32px 0 83px;">{{ formattedDate(buyout.x_created) }}</span>
                    <span>{{ buyout.number }}</span>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>

        <template #[`item.reviewed`]="{item}">
          <div
              v-if="item.reviewed"
              class="gLabel gLabel--success"
          >
            <v-icon small>$circle-check</v-icon>
            Ano
          </div>
          <div
              v-else
              class="gLabel gLabel--error"
          >
            <v-icon small>$circle-exclamation</v-icon>
            Ne
          </div>
        </template>
        <template #[`item.exported`]="{item}">
          <div
              v-if="item.exported"
              class="gLabel gLabel--success"
          >
            <v-icon small>$circle-check</v-icon>
            {{ item.exported_count }} / {{ item.batch_item_count }}
          </div>
          <div
              v-else
              class="gLabel gLabel--error"
          >
            <v-icon small>$circle-exclamation</v-icon>
            Ne
          </div>
        </template>
        <template #[`item.boss_voucher_send_count`]="{item}">
          <div
              v-if="item.boss_voucher_send_count"
              class="gLabel gLabel--success"
          >
             <v-icon small>$circle-check</v-icon>
            {{ item.boss_voucher_send_count }} / {{ item.batch_item_count }}
          </div>
          <div
              v-else
              class="gLabel gLabel--error"
          >
            <v-icon small>$circle-exclamation</v-icon>
            Ne
          </div>
        </template>


        <template #[`item.type`]="{ item }">
          <span>{{ formattedDate(item.x_created) }}</span>
        </template>
        <template #[`item.x_created`]="{ item }">
          <span>{{ formattedDate(item.x_created) }}</span>
        </template>
        <template #[`item.actions`]="{ item }">
          <div class="vDataTable__btnsWrapper">
            <v-btn
                class="primary"
                small
                @click="showReview(item)"
            >
              Zpracovat
            </v-btn>
            <v-btn
                :disabled="item.batch_item_count === item.exported_count && false"
                v-if="item.reviewed"
                class="primary"
                small
                :loading="item.loading"
                @click="openExportToBoss(item.id)"
            >
              Založit
            </v-btn>
          </div>
        </template>
        <template #no-data>
          Žádná data...
        </template>
        <template #loading>
          Načítání dat...
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
        v-model="modalAdd"
        activator="parent"
        width="auto"
    >
      <batch-create-list @crate="handleCrate()"
                         @close="modalAdd=false"
                         :batch="batch"
      ></batch-create-list>
    </v-dialog>
  </div>

</template>
<script>

import {fetchBatchList} from "./api";
import BatchCreateList from "@/components/batch/BatchCreateList.vue";
import {formattedDate} from "@/utils/formatters";
import DatePicker from "@/components/utils/DatePicker.vue";
import DialogBatchMove from "@/components/batch/DialogBatchMove.vue";


export default {
  name: 'BatchList',
  components: {DialogBatchMove, DatePicker, BatchCreateList},
  props: {},
  computed: {
    params() {
      return {
        page: this.options.page,
        page_size: this.options.itemsPerPage,
        ordering: this.options.sortBy[0],
        desc: this.options.sortDesc[0],
        search: this.search.fulltext,
        date_from: this.search.dateFrom,
        date_to: this.search.dateTo,
        date_buyout: this.search.buyout
      }
    }
  },
  data() {
    return {
      selected: [],
      expanded: [],
      loading: false,
      search: {
        buyout: "",
        fulltext: "",
        dateFrom: '',
        dateTo: '',
      },
      headers: [
        {text: 'Vytvořeno', value: 'x_created', sortable: false},
        {text: 'Várka', value: 'identifier', sortable: false},
        {text: 'Hmotnost', value: 'weight'},
        {text: 'Typ', value: 'type_batch.description', sortable: false},
        {text: 'Komodita', align: 'start', value: 'type_commodity.description', sortable: false},
        {text: 'Ryzost', align: 'start', value: 'commodity_purity.purity', sortable: false},
        {text: 'Stav', value: 'last_state_description', sortable: false},
        {text: 'Dokončeno', value: 'reviewed'},
        {text: 'Založeno', value: 'exported'},
        {text: 'Doklad', value: 'boss_voucher_send_count'},
        {text: '', value: 'actions', sortable: false, width: 240},
      ],
      grid: {
        loading: false,
        itemsPerPageOptions: [50, 100, 200],
        defaultPageSize: 100,
        count: 0,
        next: null,
        previous: null,
      },
      options: {},
      batch: {
        id: "", reviewed: false
      },
      batches: [],
      modalAdd: false,
      showFilters: false
    }
  },
  async mounted() {
    await this.getData()
  },
  watch: {
    options: {
      handler() {
        this.getData()
      },
      deep: true
    },
    search: {
      handler() {
        this.getData()
      },
      deep: true
    },
  },
  methods: {
    row_classes(item) {
      if (item.deleted)
        return "is-disabled"
      else
        return ""
    },
    openExportToBoss(id) {
      const routeData = this.$router.resolve({
        name: 'BatchSendToBoss',
        params: {id: id}
      })
      window.open(routeData.href, '_blank');
    },
    formattedDate,
    showReview(item) {
      const routeData = this.$router.resolve({
        name: 'BatchReview',
        params: {id: item.id, state: item.last_state_order ? item.last_state_order.toString() : "1"}
      })
      window.open(routeData.href, '_blank');
    },
    handleCrate() {
      this.getData()
      this.modalAdd = false
    },
    async getData() {
      this.grid.loading = true
      await fetchBatchList(this.params).then(response => {
        this.handleResponse(response)
      })
    },
    handleResponse(response) {
      this.batches = response.data.results
      this.grid.count = response.data.count
      this.grid.next = response.data.next
      this.grid.previous = response.data.previous
    },
    handleSearch() {
      this.options.page = 1
      this.getData()
    },
    handleResetSearch() {
      this.search = {
        fulltext: "",
        dateFrom: '',
        dateTo: '',
      }
    },
  }
}

</script>

<style lang="scss" scoped>
:deep(tr) {
  &.is-disabled {
    background: #cccccc;
    opacity: 0.35;

    > td > .theme--light.v-data-table {
      background: unset;
    }

    td:not(:has(.v-data-table__expand-icon)) {
      pointer-events: none;
    }
  }
}
</style>
