<template>
  <v-form @submit.prevent="saveBuyoutItems" ref="commoditiesForm" v-if="!loading">
    <v-container v-if="isReadonly">
      <v-row>
        <v-col
            cols="12"
            md="11"
        >
          <v-btn class="aBtn--primaryInv" :ripple="false" @click="itemsInfo = !itemsInfo">{{
              buyoutItemButtonText
            }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="itemsInfo || !isReadonly">
      <v-row v-for="(item,index) in buyoutItems" :key="index" justify="center">

        <v-col
            cols="12"
            md="2"
        >
          <v-select
              v-if="!isReadonly"
              v-model="item.type_commodity"
              :items="typeCommodityList"
              label="Typ komodity"
              :menu-props="{ offsetY: true }"
              outlined
              attach
              @change="change"
              :rules="[inputRules.required]"
              :item-text="(value) => `${value.description}`"
              :item-value="(value) => value"
          />
          <div v-else>
            {{ item.type_commodity.description}}
          </div>

        </v-col>

        <v-col
            cols="12"
            md="2"
        >
          <v-select
              v-if="!isReadonly"
              :disabled="item.type_commodity === undefined || isReadonly"
              v-model="item.commodity_purity"
              :items="getFilteredCommodityPurities(item.type_commodity !== undefined ? item.type_commodity.id : null)"
              label="Ryzost"
              :menu-props="{ offsetY: true }"
              outlined
              attach
              @change="change"
              :rules="[inputRules.required]"
              :item-text="(value) => `${getFormattedPurityText(value)}`"
              :item-value="(value) => value"
          />
          <div v-else>
            {{ item.commodity_purity.purity }} - {{ formatPrice(item.frozen_price) }} Kč
          </div>
        </v-col>
        <v-col
            cols="12"
            md="1"
        >
          <v-text-field
              v-model="item.weight"
              label="Hmotnost (g)"
              @change="change"
              :rules="[inputRules.required,inputRules.isValidDecimal, inputRules.minValue(0.1)]"
              :disabled="item.commodity_purity === undefined || isReadonly"
              outlined
          ></v-text-field>
        </v-col>
        <v-col
            cols="12"
            md="2"
        >
          <v-text-field
              v-model="item.surcharge"
              label="Příplatek "
              @change="change"
              :disabled="item.commodity_purity === undefined || isReadonly"
              :default="0"
              outlined
              type="number"
          ></v-text-field>
        </v-col>
        <v-col
            cols="12"
            md="2"
        >
          <v-text-field
              v-model="item.surcharge_note"
              label="Příplatek - pozn."
              @change="change"
              :disabled="item.commodity_purity === undefined || isReadonly"
              outlined
          ></v-text-field>
        </v-col>
        <v-col
            cols="12"
            md="2"
        >
          <p class="pt-2 text-left">{{ showPrice(item) }}</p>
        </v-col>
        <v-col
            cols="12"
            md="1"
        >
          <v-btn
              color="red"
              light
              fab
              plain
              small
              class="commodityForm__minusButton"
              elevation="0"

              @click.prevent="removeBuyoutItem(index)"
              v-if="!isReadonly"
          >
            <v-icon>mdi-minus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-divider/>
      <v-row>
        <v-col
            cols="12"
            md="9"
        />
        <v-col
            cols="12"
            md="3"
        >
          <p class="pt-2 text-left">Celková cena: {{ formatPrice(calcTotalPrice) }} Kč</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-btn
              block
              class="commodityForm__plusButton aBtn--primaryInv"
              elevation="0"
              outlined
              @click.prevent="addBuyoutItem"
              v-if="!isReadonly"
              plain
          >
            <v-icon>mdi-plus</v-icon>
            Přidat komoditu
          </v-btn>
        </v-col>
        <v-col cols="12" md="6">
          <v-btn
              id="scroll"
              @click="scroll"
              type="submit"
              class="primary"
              elevation="0"
              plain
              outlined
              block :disabled="isButtonDisabled" v-if="!isReadonly">Uložit
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>

</template>

<script>
import rules from '@/utils/rules.js'
import {ACTION_TYPES as AT} from "../../store/action-types";
import {
  createBuyoutItem,
  deleteBuyoutItem, fetchBatchTypes,
  fetchBuyoutItems,
  fetchCommodityPurities,
  fetchCommodityTypes,
  updateBuyoutItem,
  updateBuyoutStatus,
  updateBuyoutTotalPrice
} from "./api";
import {buyoutTotalPriceData, statusNotRedeemedData} from "./config";
import {formattedPrice} from "@/utils/formatters";

export default {
  name: 'commodityForm',
  props: {
    buyoutId: {type: String, required: true},
    isReadonly: {type: Boolean, required: false, default: false}
  },
  created() {
    this.$store.watch((state) => {
          return state.customerFormIsVisible
        },
        (newVal, oldVal) => {
          if (newVal && !oldVal) {
            this.scroll()
          }
        },
        {
          deep: true
        }
    )
  },
  data() {
    return {
      itemsInfo: false,
      loading: true,
      inputRules: {
        ...rules
      },
      buyoutItems: [],
      removedItems: [],
      typeCommodityList: [],
      typeBatchTypeList: [],
      commodityPurities: []
    }
  },
  computed: {
    defaultCommodity() {
      return {id: undefined, weight: '', type_commodity: undefined}
    },
    isButtonDisabled() {
      return this.$store.state.buyoutItemsFormIsSubmitted
    },
    calcTotalPrice() {
      let totalPrice = 0
      this.buyoutItems.forEach(value => {
        if (value.commodity_purity !== undefined) {
          totalPrice += Math.round(this.calculatePrice(value));
        }
      })
      return totalPrice
    },
    buyoutItemButtonText() {
      if (this.itemsInfo) {
        return 'Skrýt předměty výkupu'
      }
      return 'Zobrazit předměty výkupu'
    },
  },

  async mounted() {
    await fetchBatchTypes()
        .then((response) => {
          response.data.forEach(value => {
            this.typeBatchTypeList.push(value)
          })
        })
    await fetchCommodityTypes()
        .then((response) => {
          response.data.forEach(value => {
            this.typeCommodityList.push(value)
          })
        })
    await fetchCommodityPurities()
        .then((response) => {
          response.data.forEach(value => {
            this.commodityPurities.push(value)
          })
        })

    await fetchBuyoutItems(this.buyoutId)
        .then((response) => {
          response.data.forEach(value => {
            this.buyoutItems.push(value)
          })
        })
    this.loading = false
    if (this.buyoutItems.length === 0) {
      this.buyoutItems.push({...this.defaultCommodity})
    } else {
      await this.$store.dispatch(AT.saveBuyoutItems)
      await this.$store.dispatch(AT.buyoutStatusUpdate)
    }
  },
  methods: {
    scroll() {
      const element = document.getElementById("scroll");
      if (element !== null) {
        const y = element.getBoundingClientRect().top + window.pageYOffset + 45;
        window.scrollTo({top: y, behavior: 'smooth'});
      }
    },
    change() {
      this.$store.dispatch(AT.buyoutItemsUnsavedChange)
    },
    addBuyoutItem() {
      this.buyoutItems.push({...this.defaultCommodity})
    },
    removeBuyoutItem(index) {
      this.removedItems.push(this.buyoutItems[index])
      this.buyoutItems.splice(index, 1)
      this.$store.dispatch(AT.buyoutItemsUnsavedChange)
    },
    async processItemDeletion() {
      for (const index in this.removedItems) {
        if (this.removedItems[index].id !== undefined) {
          await deleteBuyoutItem(this.removedItems[index].id)
        }
      }
      this.removedItems = []
    },
    async processItemCreate(item, index) {
      const data = {
        type_commodity: item.type_commodity.id,
        weight: parseFloat(item.weight),
        buyout: this.buyoutId,
        commodity_purity: item.commodity_purity.id,
        price_for_gram: item.commodity_purity.price_for_gram,
        surcharge: item.surcharge,
        surcharge_note: item.surcharge_note
      }
      await createBuyoutItem(data)
          .then((response) => {
            this.buyoutItems[index].id = response.data.id
          })
          .catch(err => console.log(err))
    },
    async processItemUpdate(item) {
      const data = {
        type_commodity: item.type_commodity.id,
        weight: parseFloat(item.weight),
        commodity_purity: item.commodity_purity.id,
        price_for_gram: item.commodity_purity.price_for_gram,
        surcharge: item.surcharge,
        surcharge_note: item.surcharge_note
      }
      await updateBuyoutItem(item.id, data)
          .catch(err => console.log(err))
    },
    async processUpdateOrCreate() {
      for (const index in this.buyoutItems) {
        const item = this.buyoutItems[index]
        if (item.id === undefined) {
          await this.processItemCreate(item, index)
        } else {
          await this.processItemUpdate(item)
        }
      }
    },

    async saveBuyoutItems() {
      if (this.$refs.commoditiesForm.validate()) {
        await this.$store.dispatch(AT.saveBuyoutItems, this.buyoutItems)
        await this.processItemDeletion()
        await this.processUpdateOrCreate()
        await updateBuyoutTotalPrice(this.buyoutId, buyoutTotalPriceData(this.calcTotalPrice))
        if (this.$store.state.statusUpdated === false) {
          await updateBuyoutStatus(this.buyoutId, statusNotRedeemedData)
              .then(() => {
                this.$store.dispatch(AT.buyoutStatusUpdate)
              })
        }
      }
    },
    formatPrice(price) {
      return formattedPrice(price)
    },

    calculatePrice(item) {

      let sur = (item.surcharge === "" || item.surcharge === undefined) ? 0 : parseInt(item.surcharge)
      return (item.commodity_purity.price_for_gram * item.weight * (1 + (item.commodity_purity.price_deviation_percent / 100))) + sur
    },

    subtractPercentDeviation(commodityPurityItem) {
      return commodityPurityItem.price_for_gram * (1 + (commodityPurityItem.price_deviation_percent / 100))
    },

    showPrice(item) {
      if (item.commodity_purity !== undefined) {
        return `Cena: ${this.formatPrice(this.calculatePrice(item))} Kč`
      }
      return ''
    },

    getFilteredCommodityPurities(commodity_id) {
      if (commodity_id === null) {
        return []
      }
      let filtered_list = []
      this.commodityPurities.forEach(value => {
        if (value.type_commodity === commodity_id) {
          filtered_list.push(value)
        }
      })
      return filtered_list
    },
    getFormattedPurityText(commodityPurityItem) {
      return `${commodityPurityItem.purity} | ${this.formatPrice(this.subtractPercentDeviation(commodityPurityItem))} Kč `
    }
  }
}
</script>
