import {MUTATION_TYPES as MT} from "./_mutation-types";

export default {
    // user
    [MT.SHOP_SET](state, value) {
        state.userShop = value
        localStorage.setItem('user_shop', value)
    },
    [MT.CASH_REGISTER_SET](state, value) {
      state.userCashRegisterIdentifier = value
      localStorage.setItem('userCashRegisterIdentifier', value)
    },

    [MT.TOKENS_SET](state, data) {
        state.accessToken = data.access
        localStorage.setItem('access_token', data.access)
        state.refreshToken = data.refresh
        localStorage.setItem('refresh_token', data.refresh)

    },
    [MT.CREDENTIALS_SET](state, data) {
        state.username = data.username
        localStorage.setItem('username', data.username)
        state.userShop = data.userShop
        localStorage.setItem('user_shop', data.userShop)
        state.isAdmin = data.isAdmin
        localStorage.setItem('is_admin', data.isAdmin)
        state.userId = data.userId
        localStorage.setItem('user_id', data.userId)
        state.userCashRegisterIdentifier = data.userCashRegisterIdentifier
        localStorage.setItem('userCashRegisterIdentifier', data.userCashRegisterIdentifier)
        state.isGoldsmith = data.isGoldsmith
        localStorage.setItem('isGoldsmith', data.isGoldsmith)
        state.hasAllShops = data.hasAllShops
        localStorage.setItem('has_all_shops', data.hasAllShops)
    },
    [MT.CREDENTIALS_TOKENS_REMOVE](state) {
        state.accessToken = null
        localStorage.removeItem('access_token')
        state.refreshToken = null
        localStorage.removeItem('refresh_token')

        state.username = ''
        localStorage.removeItem('username')
        state.userShop = ''
        localStorage.removeItem('user_shop')
        state.isAdmin = false
        localStorage.removeItem('is_admin')
        state.userId = null
        localStorage.removeItem('user_id')
        state.userCashRegisterIdentifier = ''
        localStorage.removeItem('userCashRegisterIdentifier')
        state.isGoldsmith = false
        localStorage.removeItem('isGoldsmith')
        state.hasAllShops = false
        localStorage.removeItem('has_all_shops')

    },

    // new buyout

    [MT.BUYOUT_STATUS_UPDATE](state, status) {
        state.statusUpdated = status
    },
    [MT.BUYOUT_ITEMS_FORM_STATUS_UPDATE](state, status) {
      state.buyoutItemsFormIsSubmitted = status
    },

    [MT.CUSTOMER_FORM_STATUS_UPDATE](state, status) {
        state.customerFormIsSubmitted = status
    },
    [MT.CUSTOMER_FORM_VISIBILITY_UPDATE](state, isVisible){
        state.customerFormIsVisible = isVisible
    },
    [MT.CONTRACT_PREVIEW_VISIBILITY_UPDATE](state, isVisible) {
        state.isContractPreviewVisible = isVisible
    },
    [MT.IS_EMAIL_UPDATE](state,isEmail) {
        state.isEmail = isEmail
    },
    // errors

    [MT.ERRORS_STATE_UPDATE](state, data){
        state.isActive = data.isActive
        state.errorMessage = data.errorMessage
        state.many = data.many
        state.isStringMessage = data.isStringMessage
        state.isNormalMessage = data.isNormalMessage
    },

    // goldsmith
    [MT.GOLDSMITH_ACTIVE_ITEM_UPDATE](state, value) {
        state.activeGoldsmithItem = value
        localStorage.setItem('activeGoldsmithItem', value)
    },
    [MT.GOLDSMITH_ACTIVE_ITEM_REMOVE](state) {
        state.activeGoldsmithItem = null
        localStorage.removeItem('activeGoldsmithItem')
    },
    [MT.NO_WEIGHT_LEFT_UPDATE](state, value) {
        state.noWeightLeft = value
    },
    [MT.ALL_GOLDSMITH_ITEMS_ARE_OK_UPDATE](state, value) {
        state.allGoldsmithItemsAreOk = value
    }

}