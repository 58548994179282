<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col cols="12" md="9">
          <h2> Zpracování výkupů</h2>
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
        :headers="headers"
        :items="buyoutItems"
        :loading="loading"
        class="elevation-2"
        :hide-default-footer="true"
        disable-pagination
        show-expand
        :single-expand="true"
        :expanded.sync="expanded"
    >
      <template #[`item.sortButton`]="{ item }">
        <v-btn small @click="toggleItem(item)" class="primary">
          Zpracovat část
        </v-btn>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-row class="mt-2">
            <v-col
                cols="12"
                md="12"
            >
            </v-col>
          </v-row>
          <v-row class="mb-2">
            <v-col
                cols="12"
                md="12"
            >
              <buyout-batch-item-list :batch_item="item" @updateItem="updateItem"></buyout-batch-item-list>
            </v-col>
          </v-row>
        </td>
      </template>

      <template #no-data>
        Podmínkám neodpovídají žádná data...
      </template>
      <template #loading>
        Načítání dat...
      </template>
    </v-data-table>


    <div class="finishBuyout__container">
      <v-btn
          :disabled="canBeClosed"
          :loading="loading"
          block
          class="mt-15 primary"
          @click="handleBuyoutBatchComplete()"
      >
        Dokončit zpracovaní
      </v-btn>

    </div>

  </v-card>
</template>

<script>

import {fetchBuyoutItem, fetchBuyoutItems, updateBuyout} from "./api";
import buyoutBatchItemList from "@/components/batch/BuyoutBatchItemList.vue";


export default {
  name: 'buyoutItemList',
  components: {buyoutBatchItemList},
  props: {
    buyoutId: {required: true, type: String}
  },
  computed: {
    canBeClosed() {
      return this.buyoutItems.every(item => item.weight_left > 0.1);
    },
  },
  data() {
    return {
      expanded: [],
      loading: false,
      headers: [
        {text: 'Typ komodity', align: 'start', value: 'type_commodity.description'},
        {text: 'Ryzost', value: 'commodity_purity.purity'},
        {text: 'Celková hmotnost (g)', value: 'weight'},
        {text: 'Zbývající hmotnost (g)', value: 'weight_left'},
        {text: '', value: 'sortButton', sortable: false}
      ],
      buyoutItems: [],
      selectedItem: null
    }
  },
  async mounted() {
    this.loading = true
    await this.getData()
    this.loading = false
  },
  methods: {
    async getData() {
      await fetchBuyoutItems(this.buyoutId)
          .then(response => {
            this.buyoutItems = response.data
            this.expanded = response.data
          })
    },
    async updateItem(item) {
      await fetchBuyoutItem(item.id)
          .then(response => {
            let updatedItem = response.data
            this.buyoutItems.forEach((value, key) => {
              if (value.id === updatedItem.id) {
                this.buyoutItems[key].weight_left = updatedItem.weight_left
              }
            })
          })
    },
    toggleItem(item) {
      if (!this.expanded.includes(item)) {
        this.expanded.push(item)
      } else {
        this.expanded = this.expanded.filter(function (i) {
          return i.id !== item.id;
        });
      }
    },
    async handleBuyoutBatchComplete() {
      this.loading = true
      await updateBuyout(this.buyoutId, {batch_prepared: true})
          .then(response => {
            this.loading = false
            window.close();
          })


    }
  }
}

</script>
