import regex from "./regex";

function convertValue (v)  {
    if (typeof v === "string") {
      return parseFloat(v.replace(/,/g, ".").replace(/\s+/g, "")); // convert input with coma to dot e.g. 5,5 => 5.5
    }
    return v;
  }

export default {
    required: value => !!value || 'Pole je vyžadováno.',
    isValidDecimal: value => value.length > 0 ? ((!!value.match(regex.decimal) || !!value.match(/^[0-9]+$/)) || 'Pouze číselné hodnoty') : true,
    isValidInteger: value => value.length > 0 ? (!!value.match(regex.integer) || 'Pouze číselné hodnoty bez mezer') : true,
    isValidEmail: value => value.length > 0 ? (!!value.match(regex.email)) || 'Hodnota musí být email' : true,
    isValidDate: value => value.length > 0 ? (!!value.match(regex.date)) || 'Hodnota musí být datum ve formátu DD. MM. YYYY' : true,
    isValidDialingCode: value => value.length > 0 ? (!!value.match(regex.diallingCode)) || 'Neplatná předvolba' : true,
    maxValue: (maxValue) => (value) => (value !== undefined && value !== null && (convertValue(value) <= maxValue)) || `Maximální hodnota je ${maxValue}`,
    minValue: (minValue) => (value) => (value !== undefined && value !== null && (convertValue(value) >= minValue)) || `Minimální hodnota je ${minValue}`,
}
