<template>
  <v-simple-table>
    <template v-slot:default>
      <tbody>
      <tr>
        <th>Náhled smlouvy</th>
        <th>
          <a :href="contractUrl">
            <v-icon>mdi-file</v-icon>
          </a>
        </th>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>

import {BASE_API_URL} from "../../api/axios-base";
import {URLS} from "../../api/config";

export default {
  name: 'contract-preview',
  props: {
    buyoutId: {type: String, required: true}
  },
  computed: {
    contractUrl() {
      return `${BASE_API_URL}/${URLS.contractPreview(this.buyoutId, true)}`
    }
  },
}

</script>