<template>
  <v-dialog
      v-model="dialog"
      width="50vh"
  >
    <template v-slot:activator="{on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs"></slot>
    </template>
    <v-card height="auto">
      <v-container>
         <img width="100%" :src="image" :alt='image'>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  name: 'DialogImagePreview',
  props: {
    image: {required: true, type: String},
  },
  data() {
    return {
      dialog: false,
    }
  },
}

</script>