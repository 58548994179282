<template>
  <div class="ma-0 pa-0 newBuyout__container text-center mt-5">
    <h1>{{ title }}</h1>
    <v-card
        class="mx--center mt-5 buyoutDetail__basicInfoTable elevation-2 light"
        max-width="100%"
        v-if="isReadonly"
    >
      <InfoCard :buyoutId="$route.params.id"/>
    </v-card>

    <v-card
        class="newBuyout__commodityForm mx--center mt-5"
        elevation="3"
    >
      <v-card-title>Předměty výkupu</v-card-title>
      <BuyoutItemsForm :buyoutId="$route.params.id" :isReadonly="isReadonly"/>
    </v-card>

    <v-card
        v-if="!isReadonly && $store.state.isContractPreviewVisible"
        class="mx--center mt-5 elevation-2 light"
    >
      <ContractPreview :buyoutId="$route.params.id"/>
    </v-card>
    <v-card
        v-if="$store.state.customerFormIsVisible"
        class="newBuyout__customerForm mx--center mt-5"
        elevation="3"
    >
      <v-card-title>Informace o zákazníkovi</v-card-title>
      <CustomerForm :buyoutId="$route.params.id" :isReadonly="isReadonly"/>
      <div class="newBuyout__finishButton" v-if="!isReadonly && $store.getters.canFinishBuyout && $store.state.isEmail">
        <QrComponent key="QrComponent" :buyout-id="$route.params.id"/>
      </div>
    </v-card>
    <div class="newBuyout__finishButton newBuyout__finishButton--noEmail mt-15"
         v-if="!isReadonly && $store.getters.canFinishBuyout && !$store.state.isEmail">
      <FinishBuyoutButton :buyout-id="$route.params.id"/>
    </div>

  </div>
</template>

<script>
import BuyoutItemsForm from '@/components/newBuyout/BuyoutItemsForm'
import CustomerForm from '@/components/newBuyout/CustomerForm'
import InfoCard from "@/components/buyoutDetail/InfoCard";
import QrComponent from "@/components/newBuyout/QrComponent";
import ContractPreview from "../../components/newBuyout/ContractPreview";
import FinishBuyoutButton from "../../components/newBuyout/FinishBuyoutButton";

export default {
  name: 'newBuyout',
  props: {
    isReadonly: {type: Boolean, required: false, default: false},
    title: {type: String, required: false, default: 'Nový výkup'}
  },
  methods: {},
  components: {
    BuyoutItemsForm,
    CustomerForm,
    InfoCard,
    QrComponent,
    ContractPreview,
    FinishBuyoutButton
  }
}
</script>