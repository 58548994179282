<template>
  <div class="navbar__container">
    <v-app-bar
      app
      class="elevation-0"
      height="75px"
    >
      <v-app-bar-nav-icon
        dark
        large
        @click.stop="toggle = !toggle"
      />
      <v-toolbar-title>{{ $route.meta.sectionTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title>{{ username }}</v-toolbar-title>
      <div v-if="$store.state.isAdmin || $store.state.hasAllShops" class="navbar__storeSelect">
        <v-select
          v-if="!loading"
          v-model="activeStore"
          :items="storesList"
          :menu-props="{ offsetY: true }"
          outlined
          attach
          dark
          solo-inverted
          single-line
          label="Pobočka"
          :item-text="(value) => value.description"
          :item-value="(value) => value"
          v-on:change="updateUserData"
        />
      </div>
      <div class="navbar__store" v-else>
        <v-toolbar-title>{{ $store.state.userShop }}</v-toolbar-title>
      </div>
      <div class="navbar__storeSelect">
        <v-select
          v-model="activeCashRegister"
          :items="cashRegistersList"
          :menu-props="{ offsetY: true }"
          outlined
          attach
          dark
          solo-inverted
          single-line
          label="Pokladna"
          :item-text="(value) => value.description"
          :item-value="(value) => value"
          v-on:change="updateUserData"
          v-if="!loading"
        />
      </div>
    </v-app-bar>
    <v-navigation-drawer
      v-model="toggle"
      class="elevation-0 light"
      app
      bottom
      width="225px"
    >
      <img src="../../assets/svg/zlaton-tradein-logo.svg" class="app__logo d-flex mx-auto mt-5 mb-8">
      <v-list nav dense>
        <v-list-item-group>
          <div v-for="(link, i) in allowedRoutes" :key="i">
            <v-list-item
              v-if="!link.subLinks"
              class="elevation-0"
              :ripple="false"
              @click.stop="changeRoute(link.urlName)"
            >
              <v-list-item-icon>
                <v-icon>{{ link.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ link.name }}
              </v-list-item-title>
            </v-list-item>
            <v-list-group
              v-else
              :key="link.text"
              no-action
              :prepend-icon="link.icon"
              :value="false"
            >
              <template v-slot:activator>
                <v-list-item-title>{{ link.name }}</v-list-item-title>
              </template>
              <v-list-item
                v-for="sublink in link.subLinks"
                :key="sublink.text"
                class="elevation-0"
                :ripple="false"
                @click.stop="changeRoute(sublink.urlName)"
              >
                <v-list-item-title>
                  <div v-html="sublink.name"/>
                </v-list-item-title>
              </v-list-item>
            </v-list-group>
          </div>
        </v-list-item-group>
        <v-divider/>
        <v-list-item
          class="elevation-0 mt-2 navbar__logoutButton"
          :ripple="false"
          @click.stop="logout"
        >
          <v-list-item-icon>
            <v-icon>$logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            Odhlásit se
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {fetchCashRegisters, fetchShops, updateUserInfo} from "./api";
import {ACTION_TYPES as AT} from "@/store/action-types";
import {MUTATION_TYPES as MT} from "@/store/_mutation-types";

export default {
  name: 'navbar',

  data() {
    return {
      toggle: true,
      loading: true,
      groupModel: null,
      myRoutes: [
        {
          name: 'Nový výkup',
          urlName: 'Home',
          active: false,
          icon: '$circle-plus',
          limitationFunction: () => true,
        },
        {
          name: 'Seznam výkupů',
          urlName: 'BuyoutsList',
          urlPath: 'buyouts/list',
          active: false,
          icon: '$list',
          limitationFunction: () => true,
        },
        {
          name: 'Zpracování',
          icon: '$box',
          limitationFunction: () => true,
          subLinks: [
            {
              name: '— &nbsp; Výkupy',
              urlName: 'BuyoutProcess',
            },
            {
              name: '— &nbsp; Várky',
              urlName: 'BatchList',
            },
          ]
        },
        {
          name: 'Zlatník',
          urlName: 'Goldsmith',
          urlPath: 'goldsmith',
          active: false,
          icon: '$chest',
          limitationFunction: () => this.$store.state.isGoldsmith,
        },
        {
          name: 'Účet',
          urlName: 'Account',
          active: false,
          icon: '$user',
          limitationFunction: () => true,
        },
        {
          name: 'Django admin',
          urlName: 'Admin',
          active: false,
          icon: '$cog',
          limitationFunction: () => this.$store.state.isAdmin,
        },
      ],
      activeStore: undefined,
      storesList: [],
      storeNameIdRelationship: {},
      cashRegistersList: [],
      activeCashRegister: undefined
    }
  },
  computed: {
    allowedRoutes() {
      let routes = []
      this.myRoutes.forEach((value) => {
        if (value.limitationFunction()) {
          {
            routes.push(value)
          }
        }
      })
      return routes
    },
    username() {
      if (this.$store.state.isAdmin) {
        return `${this.$store.state.username} (admin)`
      }
      return this.$store.state.username
    }
  },
  async mounted() {
    this.loading = true

    await this.getShops()

    await fetchCashRegisters()
      .then((response) => {
        this.cashRegistersList = response.data
        this.cashRegistersList.forEach(value => {
          if (value.identifier === this.$store.state.userCashRegisterIdentifier) {
            this.activeCashRegister = value
          }
        })
      })
    if (this.activeCashRegister === undefined) {
      this.$store.commit(MT.CASH_REGISTER_SET, '')
    }
    this.loading = false
  },
  methods: {
    changeRoute(route) {
      this.$router.push({name: route});
    },
    async getShops() {
      fetchShops()
        .then(response => {
          if (response !== undefined) {
            // response.data.forEach(value => {
            //   this.storesList.push(value.description)
            //   this.storeNameIdRelationship[value.description] = value.id
            // })
            this.storesList = response.data
            this.storesList.forEach(value => {
              if (value.identifier === this.$store.state.userShop) {
                this.activeStore = value
              }
            })
          }
        })
    },
    updateUserData() {
      this.$store.dispatch(AT.updateUserData, {
        userShop: this.activeStore.identifier,
        userCashRegister: this.activeCashRegister ? this.activeCashRegister.identifier : ''
      })
        .then(
          updateUserInfo(this.$store.state.username, {
            user: this.$store.state.userId,
            default_shop: this.activeStore.id,
            current_cash_register: this.activeCashRegister ? this.activeCashRegister.id : null
          })
            .then(() => this.$router.go())
            .catch(err =>
              console.log(err))
        )
    },
    logout() {
      this.$store.dispatch(AT.logout)
      this.$router.push({name: 'Login'})
    },
  }
}

</script>