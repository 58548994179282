export const isSerialPortApiAvailable = () => {
    return navigator.serial !== undefined;
}


const parseSerialOutput = (str) => {
    const numericPart = str.match(/[+-]?\d+(\.\d+)?/);
    if (numericPart) {
        return parseFloat(numericPart[0]);
    } else {
        return null
    }
}

export const getSerialPortData = async () => {

    let port = {}
    const ports = await navigator.serial.getPorts()
    if (ports.length) {
        port = ports[0]
    } else {
        port = await navigator.serial.requestPort()
    }
    await port.open({baudRate: 9600});
    const reader = port.readable.getReader();
    const decoder = new TextDecoder();
    let executing = true
    let message = ""
    while (executing) {
        const {value, done} = await reader.read();
        if (done === true) {
            // |reader| has been canceled.
            break;
        }
        let character = decoder.decode(value)
        message += character
        if (character === '\n') {
            break;
        }
    }
    reader.releaseLock();
    port.close()
    return parseSerialOutput(message)
}


